import React, { useState } from 'react';
import Select from 'react-select';
import { Button, Card, CardBody, Col, Input, Label, Row, Table } from 'reactstrap';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';
import { dropdownOpt, exp, options } from './Schema';

const handleMandatory = (field, validation, formType) => {
    return (
        formType !== 'view' &&
        field.mandatory &&
        (field.showOn
            ? field.showKey
                ? validation.values[field.showOn]?.[field.showKey] === field.showValue
                : validation.values[field.showOn] === field.showValue
            : true)
    );
};

export const selectComponent = (props) => {
    return (
        <Row className="mb-2">
            <Col
                md={props.field.customCol ? 6 : 12}
                xl={props.field.customCol ? 6 : 12}
                lg={props.field.customCol ? 6 : 12}
                className="d-flex align-items-center"
            >
                <div>
                    <Label className="form-label fw-medium mb-0 fs-13">
                        {props.field.label}{' '}
                        {handleMandatory(props.field, props.validation, props.formType) && <span className="fw-14 text-danger">*</span>}
                    </Label>
                    <div>{props.field.helpText && <div className="text-muted fs-12 mb-2 word-wrap-break">{props.field.helpText}</div>}</div>
                </div>
            </Col>
            <Col md={props.field.customCol ? 6 : 12} xl={props.field.customCol ? 6 : 12} lg={props.field.customCol ? 6 : 12}>
                <Select
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.label}
                    isMulti={props.field.isMulti ? true : false}
                    isClearable={props.field.isMulti ? true : false}
                    id={props.field.label}
                    name={props.field.value}
                    isDisabled={props.formType === 'view'}
                    closeMenuOnSelect={!props.field.isMulti}
                    options={props.field.options}
                    placeholder={props.field.placeholder}
                    onBlur={() => props.validation.handleBlur({ target: { name: props.field.value } })}
                    onChange={(selectedOption) => {
                        props.validation.handleChange({ target: { name: props.field.value, value: selectedOption } });
                    }}
                    value={props.validation?.values[props.field.value] || ''}
                    isSearchable={true}
                    noOptionsMessage={() => 'No data found'}
                />
            </Col>
        </Row>
    );
};

export const radioButton = (props) => {
    return (
        <Row key={props.index}>
            {props?.field?.label && (
                <Col xs={6} sm={6} md={6} lg={6} xl={6} className={`${props.field.noMargin ? '' : 'mb-2'}`}>
                    <Label className="mb-1 fw-medium  fs-13">
                        {props.field.label}
                        {handleMandatory(props.field, props.validation, props.formType) && <span className="fw-14 text-danger">*</span>}
                    </Label>
                    <div>
                        {props.field.helpText && (
                            <div className={`text-muted fs-12 word-wrap-break${props.field.noMargin ? '' : 'mb-2'}`}>
                                {props.field.helpText}
                            </div>
                        )}
                    </div>
                </Col>
            )}
            <Col
                xs={props.field.label ? 6 : 12}
                sm={props.field.label ? 6 : 12}
                md={props.field.label ? 6 : 12}
                lg={props.field.label ? 6 : 12}
                xl={props.field.label ? 6 : 12}
                className={`${props.field.noMargin ? '' : 'mb-2'}`}
            >
                <>
                    {props.field?.radios?.map((ele, ind) => {
                        return (
                            <div className="form-check form-check-inline" key={ind}>
                                <Input
                                    className="form-check-input"
                                    type="radio"
                                    id={props.field.value}
                                    name={props.field.value}
                                    value={ele.val}
                                    onChange={props.validation.handleChange}
                                    onBlur={props.validation.handleBlur}
                                    checked={props.validation.values[props.field.value] === ele.val}
                                    disabled={props.formType === 'view' || props.field.disabled}
                                />
                                <Label className="mb-0">{ele.label}</Label>
                            </div>
                        );
                    })}
                </>
            </Col>
        </Row>
    );
};

const selectAndCardComponent = (props) => {
    return (
        <React.Fragment>
            <Row key={props.index}>
                {props?.field?.label && (
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} className="mb-2">
                        <Label className="mb-1 fw-medium  fs-13">
                            {props.field.label}
                            {handleMandatory(props.field, props.validation, props.formType) && <span className="fw-14 text-danger">*</span>}
                        </Label>
                        <div>
                            {props.field.helpText && <div className="text-muted fs-12 mb-2 word-wrap-break">{props.field.helpText}</div>}
                        </div>
                    </Col>
                )}
                <Col md={props.field.customCol ? 6 : 12} xl={props.field.customCol ? 5 : 12} lg={props.field.customCol ? 5 : 12}>
                    {props.formType !== 'view' && (
                        <Select
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.label}
                            id={props.field.label}
                            name={props.field.value}
                            isDisabled={props.formType === 'view'}
                            closeMenuOnSelect={true}
                            options={props.field.options}
                            placeholder={'Select'}
                            onChange={(selectedOption) => props.handleAddApp(selectedOption, props.field)}
                            isSearchable={true}
                            noOptionsMessage={() => 'No data found'}
                        />
                    )}
                </Col>
            </Row>
            <Row className="mt-2">
                {props.validation.values?.[props.field.value]?.length > 0 &&
                    props.validation.values?.[props.field.value]?.map((obj, ind) => (
                        <Col sm="auto p-2" key={ind}>
                            <Card>
                                <CardBody className="shadow-card position-relative">
                                    <i className="position-absolute top-5 end-5 ri-close-circle-line" />
                                    <div className="d-flex gap-1 align-items-center justify-content-center flex-column">
                                        <img src={obj.smallIconUrl} alt={obj.title} width={40} height={40} />
                                        <span>{obj.title}</span>
                                        <Row>
                                            <Col sm={6}>
                                                <Select
                                                    getOptionValue={(option) => option.value}
                                                    getOptionLabel={(option) => option.label}
                                                    isDisabled={props.formType === 'view'}
                                                    options={obj.options}
                                                    placeholder={'Min OS'}
                                                    value={obj.minos}
                                                    onChange={(selectedOption) =>
                                                        props.handleVersionSelectChange(
                                                            selectedOption,
                                                            obj,
                                                            ind,
                                                            props.field.value,
                                                            'minos'
                                                        )
                                                    }
                                                    isSearchable={true}
                                                    noOptionsMessage={() => 'No data found'}
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Select
                                                    getOptionValue={(option) => option.value}
                                                    getOptionLabel={(option) => option.label}
                                                    isDisabled={props.formType === 'view'}
                                                    options={obj.options}
                                                    value={obj.maxos}
                                                    placeholder={'Max OS'}
                                                    onChange={(selectedOption) =>
                                                        props.handleVersionSelectChange(selectedOption, obj, 'maxos')
                                                    }
                                                    isSearchable={true}
                                                    noOptionsMessage={() => 'No data found'}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
            </Row>
        </React.Fragment>
    );
};

const inputComponent = (props) => {
    return (
        <Row key={props.index}>
            {props?.field?.label && (
                <Col xs={6} sm={6} md={6} lg={6} xl={6} className="mb-2">
                    <Label className="mb-1 fw-medium  fs-13">
                        {props.field.label}{' '}
                        {handleMandatory(props.field, props.validation, props.formType) && <span className="fw-14 text-danger">*</span>}
                    </Label>
                    <div>{props.field.helpText && <div className="text-muted fs-12 mb-2 word-wrap-break">{props.field.helpText}</div>}</div>
                </Col>
            )}
            <Col
                xs={6}
                sm={6}
                md={props.field?.customCol ? props.field?.customCol : 6}
                lg={props.field?.customCol ? props.field?.customCol : 6}
                xl={props.field?.customCol ? props.field?.customCol : 6}
                className="mb-2"
            >
                <div className="input-group" key={props.index}>
                    <Input
                        className="form-control"
                        type={props.field.type}
                        id={props.field.value}
                        name={props.field.value}
                        placeholder={`Enter ${props.field.label ? props.field.label : ''}`}
                        value={props.validation.values?.[props.field.value]}
                        onChange={props.validation.handleChange}
                        onBlur={props.validation.handleBlur}
                        disabled={props.formType === 'view'}
                    />
                </div>
            </Col>
        </Row>
    );
};

const conditionalComponent = (props) => {
    const [optionLabel, setOptionLabel] = useState({
        miniosappversion: [],
        maxosversion: [],
        miniosOsversion: [],
        minsdkversion: [],
        minosversion: [],
        minpatchversion: [],
        minappversion: [],
        offline: [...dropdownOpt.offlineOptions],
        online: []
    });
    const [detailsArr, setDetailsArr] = useState(props?.field?.options);

    const handleActionSelect = (actionOptions, selectedOption) => {
        let arr = { ...optionLabel };
        const dataObj = actionOptions?.dropdownoptions?.find((ele) => ele?.value === selectedOption?.value);
        // dataObj.isDisabled = true;
        arr?.[actionOptions?.value].push(dataObj);
        if (dataObj && !optionLabel?.[actionOptions?.value].includes(dataObj)) setOptionLabel(arr);
    };

    return (
        <table className="w-100 ">
            {detailsArr.length > 0 &&
                detailsArr?.map((field, ind) => (
                    <tr key={ind} className="mb-2 border-bottom">
                        <td className="px-2 py-1 fw-medium">
                            {field.label}
                            {/* <Select
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                                id={field.label}
                                className="min-wdth-250 max-width-250"
                                name={field.labelDropdownValue}
                                isDisabled={true}
                                closeMenuOnSelect={!props.field.isMulti}
                                options={props?.field?.options}
                                placeholder={'Select one'}
                                onBlur={() => props.validation.handleBlur({ target: { name: field.labelDropdownValue } })}
                                onChange={(selectedOption) => {
                                    props.validation.handleChange({
                                        target: { name: field.labelDropdownValue, value: selectedOption }
                                    });
                                }}
                                value={field.labelDropdownValue || ''}
                                isSearchable={true}
                                noOptionsMessage={() => 'No data found'}
                            /> */}
                            {/* {handleMandatory(field, props.validation, props.formType) && <span className="fw-14 text-danger">*</span>} */}
                        </td>
                        <td className="px-2 ">
                            {field.inputValue && (
                                <div className="input-group">
                                    <Input
                                        className="form-control"
                                        id={field.inputValue}
                                        type={field.type}
                                        name={field.inputValue}
                                        max={
                                            field.type === 'date'
                                                ? new Date().toISOString().split('T')[0]
                                                : props.validation.values[field.dropDownValue]?.max
                                                ? props.validation.values[field.dropDownValue]?.max
                                                : ''
                                        }
                                        placeholder={`Enter ${field?.placeHolder ? field?.placeHolder : field?.label}`}
                                        value={props.validation.values[field.inputValue]}
                                        onChange={props.validation.handleChange}
                                        onBlur={props.validation.handleBlur}
                                        disabled={props.formType === 'view'}
                                    />
                                </div>
                            )}
                        </td>
                        <td className="px-2 ">
                            <Select
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => <div>{option.label}</div>}
                                id={field.label}
                                className="min-wdth-150 max-width-150"
                                name={field.dropDownValue}
                                isDisabled={props.formType === 'view'}
                                closeMenuOnSelect={!props.field.isMulti}
                                options={field.dropdownoptions}
                                placeholder={'Select Action'}
                                onBlur={() => props.validation.handleBlur({ target: { name: field.dropDownValue } })}
                                onChange={(selectedOption) => {
                                    props.validation.handleChange({ target: { name: field.dropDownValue, value: selectedOption } });
                                    handleActionSelect(field, selectedOption);
                                }}
                                value={props.validation?.values[field.dropDownValue] || ''}
                                isSearchable={true}
                                noOptionsMessage={() => 'No data found'}
                            />
                        </td>
                    </tr>
                ))}
        </table>
    );
};

const conditionsComponent = (props) => {
    return (
        <table className="w-100">
            {props.field?.fields?.map((field, ind) => (
                <tr key={ind} className="mb-2">
                    <td className="px-2 py-1 fw-medium">
                        {field.label}
                        {handleMandatory(field, props.validation, props.formType) && <span className="fw-14 text-danger">*</span>}
                    </td>
                    <td className="px-2 py-1">
                        {field.inputValue && (
                            <div className="input-group">
                                <Input
                                    className="form-control"
                                    type={field.type}
                                    id={field.inputValue}
                                    name={field.inputValue}
                                    max={
                                        field.type === 'date'
                                            ? new Date().toISOString().split('T')[0]
                                            : props.validation.values[field.dropDownValue]?.max
                                            ? props.validation.values[field.dropDownValue]?.max
                                            : ''
                                    }
                                    placeholder={`Enter ${field?.placeHolder ? field?.placeHolder : field?.label}`}
                                    value={props.validation.values[field.inputValue]}
                                    onChange={props.validation.handleChange}
                                    onBlur={props.validation.handleBlur}
                                    disabled={props.formType === 'view'}
                                />
                            </div>
                        )}
                    </td>
                    <td className="px-2 py-1">
                        <Select
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.label}
                            id={field.label}
                            className="min-wdth-150 max-width-150"
                            name={field.dropDownValue}
                            isDisabled={field.options?.length === 1}
                            closeMenuOnSelect={!props.field.isMulti}
                            options={field.options}
                            placeholder={'Select Action'}
                            onBlur={() => props.validation.handleBlur({ target: { name: field.dropDownValue } })}
                            onChange={(selectedOption) => {
                                props.validation.handleChange({ target: { name: field.dropDownValue, value: selectedOption } });
                            }}
                            value={props.validation?.values[field.dropDownValue] || ''}
                            isSearchable={true}
                            noOptionsMessage={() => 'No data found'}
                        />
                    </td>
                </tr>
            ))}
        </table>
    );
};

const tableInput = (props) => {
    const handleAdd = () => {
        let values = [...(props.validation.values[props.field.value] || [])];
        if (props.field.max ? values?.length <= props.field.max : true) values.push(props.validation.values[props.field.val]);
        props.validation.setValues({ ...props.validation.values, [props.field.value]: values, [props.field.val]: '' });
    };
    const handleDelete = (ind) => {
        let values = [...(props.validation.values[props.field.value] || [])];
        values.splice(ind, 1);
        props.validation.setValues({ ...props.validation.values, [props.field.value]: values, [props.field.val]: '' });
    };

    return (
        <React.Fragment key={props.index}>
            <Row>
                <Col sm={6}>
                    {props.field.showHeader ? (
                        <Label className="mb-1 fw-medium  fs-13">
                            {props.field.label} {props.field.mandatory && <span className="fw-14 text-danger">*</span>}
                        </Label>
                    ) : (
                        ''
                    )}
                    <div>{props.field.helpText && <div className="text-muted fs-12 mb-2 word-wrap-break">{props.field.helpText}</div>}</div>
                </Col>
                <Col sm={6}>
                    <Table bordered={props.validation.values?.[props.field.value]?.length === 1}>
                        <tbody>
                            {(props.validation.values?.[props.field.value] || [])?.map((value, ind) => (
                                <tr key={ind}>
                                    <td>
                                        <div className="d-flex align-items-center justify-content-between">
                                            {value}
                                            {props.formType !== 'view' && (
                                                <Button color="danger" outline size="sm" onClick={() => handleDelete(ind)}>
                                                    <i className="ri-delete-bin-6-line fs-16" />
                                                </Button>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {(props.field.max && props.validation.values[props.field.value]?.length
                                ? props.validation.values[props.field.value]?.length < props.field.max
                                : true) && props.formType !== 'view' ? (
                                <tr>
                                    <td className={`${props.validation.values?.[props.field.value]?.length === 1 ? '' : 'border-none'}`}>
                                        <div className="d-flex align-items-center gap-2">
                                            <Input
                                                name={props.field?.val}
                                                id={props.field?.val}
                                                className="form-control"
                                                autoComplete="new-password"
                                                placeholder={'Enter'}
                                                type={props.field.type}
                                                validate={{ required: { value: true } }}
                                                onChange={
                                                    props.handleValidationChange
                                                        ? props.handleValidationChange
                                                        : props.validation?.handleChange
                                                }
                                                min={props.field.minValue}
                                                max={props.field.maxValue}
                                                maxLength={props.field.maxLength}
                                                onBlur={props.validation?.handleBlur}
                                                onKeyDown={(e) => props.handleKeyDown(e)}
                                                value={props.validation?.values[props.field?.val] || ''}
                                                invalid={
                                                    props.validation?.touched?.[props.field?.val] &&
                                                    props.validation?.errors?.[props.field?.val]
                                                        ? true
                                                        : false
                                                }
                                            />
                                            <Button
                                                color="success"
                                                outline
                                                size="sm"
                                                disabled={
                                                    !props.validation.values[props.field.val] ||
                                                    props.validation.values[props.field.val] === ''
                                                }
                                                onClick={handleAdd}
                                            >
                                                <i className="ri-check-line fs-16" />
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ) : !props.validation.values[props.field.value]?.length ? (
                                '–'
                            ) : (
                                ''
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    );
};
const renderValue = (field, value) => {
    return field.inputType === 'dropdown'
        ? field.options?.find((option) => option.value === value)?.label
        : field.inputType === 'checkbox'
        ? value
            ? 'Yes'
            : 'No'
        : value;
};

const tableObject = (props) => {
    const handleDisable = () => {
        let flag = false;
        flag = props.field.mandatory?.every(
            (content) => (flag = !props.validation.values[content] || props.validation.values[content] === '')
        );

        if (props.field.conditionalMandatory?.length > 0) {
            if (flag) return;
            flag = !props.field.conditionalMandatory?.some((mandatory) => (flag = !!props.validation.values[mandatory]));
        }
        return flag;
    };

    const handleAdd = () => {
        let values = [...(props.validation.values[props.field.value] || [])];
        let obj = {};
        let clearObj = {};
        props.field.tableContent.forEach((content) => {
            obj[content.saveKey ? content.saveKey : content.value] =
                content.inputType === 'dropdown'
                    ? props.validation.values[content.value]?.[content.optionValue ? content.optionValue : 'value']
                    : content.inputType === 'checkbox'
                    ? !!props.validation.values[content.value]
                    : props.validation.values[content.value]
                    ? props.validation.values[content.value]
                    : undefined;
            clearObj[content.value] = '';
        });
        if (props.field.max ? values?.length <= props.field.max : true) values.push(JSON.parse(JSON.stringify(obj)));
        props.validation.setValues({ ...props.validation.values, ...clearObj, [props.field.value]: values });
    };

    const handleDelete = (ind) => {
        let values = [...(props.validation.values[props.field.value] || [])];
        values.splice(ind, 1);
        let clearObj = {};
        props.field.tableContent.forEach((content) => (clearObj[content.saveKey ? content.saveKey : content.value] = ''));
        props.validation.setValues({ ...props.validation.values, ...clearObj, [props.field.value]: values });
    };

    return (
        <React.Fragment key={props.index}>
            <Row>
                <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                    <Label className="mb-0 fw-medium d-flex align-items-center">{props.field.label}</Label>
                    <div className="text-muted font-size-12 mb-2 word-wrap-break ">{props.field.helpText && props.field.helpText}</div>
                </Col>
                <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                    <Table bordered>
                        {props.field.showHeader && (
                            <thead className="bg-light text-muted">
                                <tr>
                                    {props.field.tableContent?.map((content, index) => (
                                        <td key={index}>
                                            {content.label}
                                            {props.field.mandatory?.includes(content.saveKey ? content.saveKey : content.value) && (
                                                <span className="text-danger ms-1">*</span>
                                            )}
                                        </td>
                                    ))}
                                    <td>Actions</td>
                                </tr>
                            </thead>
                        )}
                        <tbody>
                            {(props.validation.values?.[props.field.value] || [])?.map((value, ind) => (
                                <tr key={ind}>
                                    {props.field.tableContent?.map((content, index) => (
                                        <td className={content.columnClass} key={index}>
                                            {renderValue(content, value[content.saveKey ? content.saveKey : content.value])}
                                        </td>
                                    ))}
                                    {props.formType !== 'view' && (
                                        <td className="d-flex justify-content-center">
                                            <Button color="danger" outline size="sm" onClick={() => handleDelete(ind)}>
                                                <i className="ri-delete-bin-6-line fs-16" />
                                            </Button>
                                        </td>
                                    )}
                                </tr>
                            ))}
                            {(props.field.max && props.validation.values[props.field.value]?.length
                                ? props.validation.values[props.field.value]?.length < props.field.max
                                : true) &&
                                (props.formType !== 'view' ? (
                                    <tr
                                        className={`${
                                            props.validation.values?.[props.field.value]?.length === 1 || props.field.showHeader
                                                ? ''
                                                : 'border-none'
                                        }`}
                                    >
                                        {props.field.tableContent?.map((content, index) => (
                                            <td
                                                key={index}
                                                className={`${content.columnClass ? content.columnClass : ''} ${
                                                    props.validation.values?.[props.field.value]?.length === 1 || props.field.showHeader
                                                        ? ''
                                                        : 'border-none px-0'
                                                }`}
                                            >
                                                <Row className="p-0 justify-content-center">
                                                    <DynamicComponents
                                                        noMargin={true}
                                                        field={content}
                                                        index={index}
                                                        formType={props.formType}
                                                        selected={false}
                                                        setSelected={props.setSelected}
                                                        validation={
                                                            props.handleValidationChange ? props.handleValidationChange : props.validation
                                                        }
                                                        handleKeyDown={props.handleKeyDown}
                                                        selectedApp={props.selectedApp}
                                                        isAsset={props.isAsset}
                                                        singleApps={props.singleApps}
                                                    />
                                                </Row>
                                            </td>
                                        ))}
                                        <td
                                            className={`${
                                                props.validation.values?.[props.field.value]?.length === 1 || props.field.showHeader
                                                    ? ''
                                                    : 'border-none'
                                            } d-flex justify-content-center`}
                                        >
                                            <Button color="success" outline size="sm" disabled={handleDisable()} onClick={handleAdd}>
                                                <i className="ri-check-line fs-16" />
                                            </Button>
                                        </td>
                                    </tr>
                                ) : !props.validation.values[props.field.value]?.length ? (
                                    '–'
                                ) : (
                                    ''
                                ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export const ipTable = (props) => {
    const [state, setState] = useState(false);

    const commonAssign = (option, boolean) => {
        let options1 = JSON.parse(JSON.stringify(props.options));
        options1.forEach((ip) => {
            if (ip.displayName === option.displayName) {
                ip.checked = boolean;
            }
        });
        props.setLocations(options);
    };

    const handleActionChange = (option) => {
        commonAssign(option, !option.checked);
    };

    const handleRemoveIp = (ind, option) => {
        commonAssign(option, false);
    };

    const handleAddAddress = () => {
        props.handleAddLocation(props.field);
        setState(!state);
    };

    const handleClearAddress = () => {
        props.validation.setValues({ ...props.validation.values, ipNameAllow: '', ipsAllow: '' });
        setState(!state);
    };

    return (
        <React.Fragment key={props.index}>
            <Row className="mt-2">
                <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                    {props.formType !== 'view' ? (
                        <Select
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => (
                                <div className="d-flex">
                                    <div className="pe-2">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="checkbox"
                                            disabled={props.mode === 'view'}
                                            checked={option.checked}
                                        />
                                    </div>
                                    <div>
                                        <div>{option.displayName}</div>
                                        <div className="text-muted">{option.ipAddresses}</div>
                                    </div>
                                </div>
                            )}
                            options={props?.options}
                            placeholder="Select"
                            isDisabled={props.formType === 'view'}
                            onChange={(option) => {
                                handleActionChange(option);
                            }}
                            value={props.validation?.values[props.field.value]?.action || ''}
                            isSearchable={true}
                            noOptionsMessage={() => 'No data found'}
                        />
                    ) : props.validation?.values?.[props.field.value]?.length > 0 ? (
                        props.validation?.values?.[props.field.value]
                            ?.map((v) => v.label)
                            ?.toString()
                            ?.split(',')
                            ?.join(', ')
                    ) : props.validation?.values[props.field.value]?.label ? (
                        props.validation?.values[props.field.value]?.label
                    ) : props.validation?.values[props.field.value] ? (
                        props.validation?.values[props.field.value]
                    ) : (
                        '–'
                    )}
                    {!state && (
                        <div className="text-end py-4 text-success  fs-14 fw-semibold cursor-pointer">
                            <span onClick={() => setState(true)}>+ Add New</span>
                        </div>
                    )}
                    {state && (
                        <Row className="me- my-4">
                            <Col xs={12} sm={12} md={6} lg={5} xl={5} xxl={5}>
                                <Input
                                    type="text"
                                    placeholder="Enter Name"
                                    className="form-control"
                                    id={'ipNameAllow'}
                                    name={'ipNameAllow'}
                                    value={props.validation.values?.['ipNameAllow'] || ''}
                                    onChange={props.validation.handleChange}
                                    onBlur={props.validation.handleBlur}
                                    disabled={props.formType === 'view'}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={5} xl={5} xxl={5}>
                                <Input
                                    type="text"
                                    placeholder="Enter IP Address"
                                    className="form-control"
                                    id={'ipsAllow'}
                                    name={'ipsAllow'}
                                    value={props.validation.values?.['ipsAllow'] || ''}
                                    onChange={props.validation.handleChange}
                                    onBlur={props.validation.handleBlur}
                                    disabled={props.formType === 'view'}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={2} xl={2} xxl={2} className="d-flex align-items-center">
                                <div onClick={handleClearAddress}>
                                    <i className="ri-close-line badge-soft-danger cursor-pointer fs-24 p-1 mx-2" />
                                </div>
                                <div onClick={handleAddAddress}>
                                    <i className="ri-check-line badge-soft-success cursor-pointer fs-24 p-1 mx-2" />
                                </div>
                            </Col>
                        </Row>
                    )}
                    <Row className="mx-1">
                        {props.options
                            .filter((ip) => ip.checked === true)
                            .map((ip, ind) => (
                                <Card key={ind} className="border rounded-0 p-3 m-0">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                            <div className="text-muted">{ip.displayName}</div>
                                            <div>{ip.ipAddresses}</div>
                                        </div>
                                        <div onClick={() => handleRemoveIp(ind, ip)}>
                                            <i className="ri-close-circle-line fs-18 curosor-pointer" />
                                        </div>
                                    </div>
                                </Card>
                            ))}
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export const DynamicComponents = (props) => {
    return (
        <React.Fragment key={props.index}>
            {props?.field?.inputType === 'select'
                ? selectComponent({ ...props })
                : props.field.inputType === 'radios'
                ? radioButton({ ...props })
                : props.field?.inputType === 'selectAndCards'
                ? selectAndCardComponent({ ...props })
                : props.field?.inputType === 'input'
                ? inputComponent({ ...props })
                : props.field.inputType === 'conditions'
                ? conditionsComponent({ ...props })
                : props.field.inputType === 'tableInput'
                ? tableInput({ ...props })
                : props.field.inputType === 'tableObject'
                ? tableObject({ ...props })
                : props.field.inputType === 'ipTable'
                ? ipTable({ ...props })
                : props.field.inputType === 'conditional'
                ? conditionalComponent({ ...props })
                : ''}
        </React.Fragment>
    );
};
