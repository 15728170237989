/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable multiline-comment-style */
import React, { useEffect, useState } from 'react';
import Loader from '../../../Components/Common/Loader';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import { APIClient } from '../../../helpers/api_helper';
import WindowsImage from '../../../assets/images/windowslogo.svg';

import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';
import { useEnv } from '../../../envContext';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';
import CommonModal from '../../../Components/Common/CommonModal';
import { FilePond } from 'react-filepond';
import CryptoJS from 'crypto-js';
import SimpleBar from 'simplebar-react';
import image2 from '../../../assets/images/users/avatar-2.jpg';
import image3 from '../../../assets/images/users/avatar-3.jpg';
import image5 from '../../../assets/images/users/avatar-5.jpg';
import { convertBytesToWithDecimals, Tooltip } from '../../../Components/Common/Util';
import { toast } from 'react-toastify';
import toastMessages from '../../../common/messages/toastMessages';

const WindowsApps = () => {
    document.title = 'Windows Apps';

    let history = useHistory();
    const api = new APIClient();
    const urlconf = useEnv();
    const [loading, setLoading] = useState(false);
    const [fileUploader, setFileUploader] = useState('');
    const [appsData, setAppsData] = useState([]);
    const [addCustomAppModal, setAddCustomAppModal] = useState(false);
    const [addStoreAppModal, setAddStoreAppModal] = useState(false);
    const [appName, setappName] = useState('');
    const [files, setFiles] = useState('');

    const [value, setValue] = useState('');
    const onChangeData = (val) => {
        getEventLisners();
        getAppStoreData(val);
        setValue(val);
    };

    const getAppStoreData = (val) => {
        api.get('https://apps.microsoft.com/api/products/getAutosuggestions?prefix=whatsapp', {}, '', true).then((resp) => {
            //
        });
    };

    const getEventLisners = () => {
        var searchOptions = document.getElementById('search-close-options');
        var dropdown = document.getElementById('search-dropdown');
        var searchInput = document.getElementById('search-options');

        searchInput?.addEventListener('focus', function () {
            var inputLength = searchInput.value.length;
            if (inputLength > 0) {
                dropdown?.classList.add('show');
                searchOptions?.classList.remove('d-none');
            } else {
                dropdown?.classList.remove('show');
                searchOptions?.classList.add('d-none');
            }
        });

        searchInput?.addEventListener('keyup', function () {
            var inputLength = searchInput.value.length;
            if (inputLength > 0) {
                dropdown?.classList.add('show');
                searchOptions?.classList.remove('d-none');
            } else {
                dropdown?.classList.remove('show');
                searchOptions?.classList.add('d-none');
            }
        });

        searchOptions?.addEventListener('click', function () {
            searchInput.value = '';
            dropdown?.classList.remove('show');
            searchOptions?.classList.add('d-none');
        });

        document.body.addEventListener('click', function (e) {
            if (e.target.getAttribute('id') !== 'search-options') {
                dropdown?.classList.remove('show');
                searchOptions?.classList.add('d-none');
            }
        });
    };

    useEffect(() => {
        getApps();
    }, []);

    const getApps = () => {
        setLoading(true);
        let params = { page: 1, size: 2000 };
        api.get(url.WINDOWS_APPS, params, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data?.length > 0) {
                    setAppsData(resp.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleRefresh = () => {
        // setLoading(true);
        // api.patch(url.IOSAPPS_SYNC, {}, false, domains.WINDOWS_V1)
        //     .then((resp) => {
        //         setLoading(false);
        //     })
        //     .catch((_err) => {
        //         setLoading(false);
        //     });
    };

    const CustomAppHeader = () => {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <Label className="p-0 m-0 mt-3 fw-semibold">Add New App</Label>
            </div>
        );
    };

    const StoreAppHeader = () => {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <Label className="p-0 m-0 mt-3 fw-semibold">Add Microsoft Store App</Label>
            </div>
        );
    };

    const CustomAppBody = () => {
        return (
            <Row className="d-flex align-items-center justify-content-center">
                <Label htmlFor="name" className="mb-0 ps-2">
                    App Name
                    <span className="red-color ps-1">*</span>
                </Label>
                <div className="px-2 mb-2">
                    <Input
                        className="my-2"
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter App Name"
                        value={appName}
                        onChange={(e) => onEnterAppName(e.target.value)}
                    />
                </div>
                <div className="filepondmarginremoval w-100">
                    <FilePond
                        name="files"
                        minFileSize="5KB"
                        maxFileSize="300MB"
                        maxFiles={1}
                        files={files}
                        className="filepond filepond-input-multiple"
                        onupdatefiles={(fileItems) => handleFiles(fileItems)}
                    />
                </div>
            </Row>
        );
    };

    const StoreAppBody = () => {
        return (
            <div className="app-search d-none d-md-block">
                <div className="position-relative">
                    <Input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        id="search-options"
                        value={value}
                        onChange={(e) => {
                            onChangeData(e.target.value);
                        }}
                    />
                    <span className="ri-search-line search-widget-icon"></span>
                    <span
                        className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                        id="search-close-options"
                    ></span>
                </div>
                <div className="dropdown-menu dropdown-menu-lg" id="search-dropdown">
                    <SimpleBar className="max-height-320">
                        <div className="notification-list">
                            <Link to="#" className="dropdown-item notify-item py-2">
                                <div className="d-flex">
                                    <img src={image2} className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                    <div className="flex-1">
                                        <h6 className="m-0">Angela Bernier</h6>
                                        <span className="fs-11 mb-0 text-muted">Manager</span>
                                    </div>
                                </div>
                            </Link>

                            <Link to="#" className="dropdown-item notify-item py-2">
                                <div className="d-flex">
                                    <img src={image3} className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                    <div className="flex-1">
                                        <h6 className="m-0">David Grasso</h6>
                                        <span className="fs-11 mb-0 text-muted">Web Designer</span>
                                    </div>
                                </div>
                            </Link>

                            <Link to="#" className="dropdown-item notify-item py-2">
                                <div className="d-flex">
                                    <img src={image5} className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                    <div className="flex-1">
                                        <h6 className="m-0">Mike Bunch</h6>
                                        <span className="fs-11 mb-0 text-muted">React Developer</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </SimpleBar>
                </div>
            </div>
        );
    };

    const onEnterAppName = (e) => {
        setappName(e);
    };

    const handleFiles = (fileItems) => {
        setFiles(fileItems);
        if (fileItems.length > 0) {
            let fileReader = new FileReader();
            fileReader.readAsDataURL(fileItems[0].file);
            fileReader.addEventListener('loadend', (e) => {
                if (e.target.readyState === FileReader.DONE) {
                    const hash = CryptoJS.SHA256(fileReader.result).toString(CryptoJS.enc.Hex);
                }
            });
        }
    };

    const uploadFile = () => {
        fileUploader?.click();
    };

    const handleUpload = (ev) => {
        const file = ev.target.files[0];
        if (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (fileExtension === 'msi' || fileExtension === 'msix' || fileExtension === 'exe') {
                let formData = new FormData();
                formData.append('file', file);
                setLoading(true);
                api.create(url.WINDOWS_APP_UPLOAD, formData, true, domains.WINDOWS_V1)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.status === 'success') {
                            toast.success(toastMessages.fileUploaded);
                        }
                    })
                    .finally(() => setLoading(false));
            } else {
                toast.error('Please select a valid .msi/.msix/.exe file');
            }
        }
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb title="Windows Apps" pageTitle="Windows Apps" backLink="windowsapps" history={history} />
                    <Card className="min-height-600">
                        <CardHeader>
                            <Row className="d-flex align-items-center justify-content-between">
                                {/* <Col className="col-5 d-flex align-items-center">
                                    <div className="w-50 pe-5">
                                        <SearchOption />
                                    </div>
                                    <div className="w-30">
                                        <Select options={options} />
                                    </div>
                                </Col> */}
                                <Col className="d-flex align-items-center justify-content-end">
                                    {/* <div className="text-grey fw-medium fs-12 pe-2">
                                    <i>Last Synced 5 minutes ago</i>
                                </div>
                                <div
                                    className="cursor-pointer width-30 rounded d-flex align-items-center justify-content-center bg-soft-info"
                                    onClick={handleRefresh}
                                >
                                    <i className="ri-refresh-line text-success fs-16"></i>
                                </div> */}
                                    <div className="d-flex" id="customAppUpload">
                                        <Button type="button" color="primary" onClick={() => uploadFile()}>
                                            + Custom App
                                        </Button>
                                        {Tooltip('customAppUpload', 'Upload .msi/.msix/.exe File')}
                                        <input
                                            hidden
                                            id="file"
                                            type="file"
                                            accept=".msi,.msix,.exe"
                                            ref={(file) => setFileUploader(file)}
                                            onChange={(ev) => handleUpload(ev)}
                                            onClick={(ev) => (ev.target.value = null)}
                                        />
                                    </div>
                                    {/* <div className="ps-2">
                                        <Button type="button" color="primary" onClick={() => setAddStoreAppModal(true)}>
                                            + Add Microsoft Store App
                                        </Button>
                                    </div> */}
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                {appsData?.length > 0 ? (
                                    appsData.map((data, index) => (
                                        <Col key={index} xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                                            <Card className="shadow-card border">
                                                <CardBody>
                                                    <Row className="d-flex justify-content-center align-items-center">
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={2} xxl={2}>
                                                            <img
                                                                // className="border rounded"
                                                                src={data.icon ? data.icon : WindowsImage}
                                                                alt="img"
                                                                height={45}
                                                                width={45}
                                                            />
                                                        </Col>
                                                        <Col xs={9} sm={9} md={9} lg={9} xl={10} xxl={10} className="ps-4">
                                                            <div className="fs-13 fw-semibold">
                                                                <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                                    {data.name}
                                                                </EllipsisToolTip>
                                                            </div>
                                                            <div className="fs-11 text-muted">
                                                                Version {data.version ? data.version : '1.0.0'}
                                                            </div>
                                                            <div className="fs-11 text-muted">
                                                                {convertBytesToWithDecimals(data.size, 2)}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                                <CardFooter>
                                                    {data.assignedlicenses || data.availablelicenses ? (
                                                        <div className="mt-auto">
                                                            <div className="d-flex mb-1">
                                                                <div className="flex-grow-1">
                                                                    <div className="fw-medium fs-11">Licenses</div>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <div className="d-flex align-items-center">
                                                                        <i className="ri-list-check align-bottom me-1 text-muted fs-11"></i>
                                                                        <span className="fw-medium fs-11">
                                                                            {data.assignedlicenses}/{data.availablelicenses}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="progress progress-sm animated-progess">
                                                                <div
                                                                    className="progress-bar bg-success"
                                                                    role="progressbar"
                                                                    style={{ width: data.assignedlicenses * 5.2 }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex align-items-center">
                                                            <i className="ri-information-line fs-14 pe-1" />
                                                            <i className="text-grey fw-medium fs-11 word-ellipsis">
                                                                No License is required.
                                                            </i>
                                                        </div>
                                                    )}
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                    ))
                                ) : (
                                    <NoRecordsFound />
                                )}
                            </Row>
                        </CardBody>
                    </Card>
                    <CommonModal
                        size={'md'}
                        show={addCustomAppModal}
                        disabled={true}
                        saveText="Save"
                        cancelText="Cancel"
                        modalheader={CustomAppHeader()}
                        onCloseClick={() => setAddCustomAppModal(false)}
                        handleClick={() => {}}
                        handleModalBody={() => CustomAppBody()}
                    />
                    <CommonModal
                        size={'md'}
                        show={addStoreAppModal}
                        centered={false}
                        disabled={true}
                        saveText="Save"
                        cancelText="Cancel"
                        modalheader={StoreAppHeader()}
                        onCloseClick={() => setAddStoreAppModal(false)}
                        handleClick={() => {}}
                        handleModalBody={() => StoreAppBody()}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default WindowsApps;
