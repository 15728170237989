/* eslint-disable no-prototype-builtins */
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import TabsComponent from '../../../Components/Common/TabsComponent';
import { getAppProtectionPathParams, handleAPPTime, sortBy } from '../../../Components/Common/Util';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { DynamicComponents } from './AppComponents';
import {
    ACCESS_REQUIREMENT_SCHEMA,
    ALLOW,
    CONDITIONAL_LAUNCH,
    dataProtectionSchema,
    dropdownOpt,
    exp,
    groupsSchema,
    NOT_REQUIRED,
    options,
    radioField,
    REQUIRED,
    RESTRICT
} from './Schema';
import Select from 'react-select';
import { useEnv } from '../../../envContext';
import CustomStepperComponent from '../../../Components/Common/CustomStepperComponent';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import TableContainer from '../../../Components/Common/TableContainer';
import { toast } from 'react-toastify';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';
import android1 from '../../../assets/images/android1.png';
import appleLogo from '../../../assets/images/applelogo.svg';

const EditApp = () => {
    const urlconf = useEnv();
    const history = useHistory();
    const api = new APIClient();
    const formTypeAndId = getAppProtectionPathParams(window.location.hash);
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState('view');
    document.title = mode === 'view' ? 'View App Protection' : 'Edit App Protection';
    const [groups, setGroups] = useState([]);
    const [selectedTab, setSelectedTab] = useState('dataTransfer');
    const [formData, setFormData] = useState('');
    const [applications, setApplications] = useState([]);
    const [applicationsBackup, setApplicationsBackup] = useState([]);
    const [editMode, setEditMode] = useState('');
    const [appsArr, setAppsArr] = useState([]);
    const [appVersionSchema, setAppVerisonSchema] = useState([
        {
            label: 'Require App Version',
            inputType: 'radios',
            value: 'requireAppVersion',
            helpText: 'Display name of the profile - will be shown on the device',
            radios: [
                { label: 'Require', val: REQUIRED },
                { label: 'Not Required', val: NOT_REQUIRED }
            ]
        },
        {
            label: 'Apps',
            inputType: 'selectAndCards',
            value: 'selectedApps',
            customCol: true,
            helpText: 'Display name of the profile - will be shown on the device',
            options: applications,
            showOn: 'requireAppVersion',
            showValue: REQUIRED
        }
    ]);

    const platformObj = { android: { label: 'Android', icon: android1 }, ios: { label: 'Apple', icon: appleLogo } };

    const toastMessage = {
        apps: 'Apps updated successfully.',
        config: 'App Protection config updated successfully.',
        assignment: 'Assignment updated successfully.'
    };

    useEffect(() => {
        handlePromise(formTypeAndId.platform === 'android' ? url.MS_APP_PROTECTION : url.APPLE_APP_PROTECTION);
    }, []);

    const handlePromise = (apiUrl) => {
        setLoading(true);
        const policyPromise = new Promise((resolve, reject) =>
            api
                .get(apiUrl + '/' + formTypeAndId.recordID, '', domains.MDM_COMMONS)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') resolve(resp);
                    else reject('App failed');
                })
                .catch((err) => reject(err))
        );

        const appsPromise = new Promise((resolve, reject) =>
            api
                .get(
                    formTypeAndId.platform === 'ios' ? url.APPLE_APP_PROTECTION + '/apps' : url.APP_PROTECTION_APPS,
                    '',
                    domains.MDM_COMMONS
                )
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.data) resolve(resp.data);
                    else reject('Apps failed');
                })
                .catch((err) => reject(err))
        );

        const groupsPromise = new Promise((resolve, reject) => {
            api.get(url.APP_PROTECTION_GROUPS, '', domains.MDM_COMMONS)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Groups failed.');
                })
                .catch((err) => reject(err));
        });

        Promise.allSettled([policyPromise, appsPromise, groupsPromise])
            .then(async (result) => {
                let obj = {};
                let assignedGroups =
                    result[0].status === 'fulfilled' && result[0]?.value?.data?.assignments?.length > 0
                        ? result[0]?.value?.data?.assignments
                              ?.map((group) =>
                                  formTypeAndId.platform === 'android'
                                      ? group.target?.groupId
                                          ? group.target?.groupId
                                          : group.groupId
                                      : group.target?.groupId
                              )
                              ?.join(',')
                        : '';
                if (result[1].status === 'fulfilled') {
                    let apps =
                        result[1].status === 'fulfilled'
                            ? result[1].value?.filter((app) =>
                                  formTypeAndId.platform === 'android'
                                      ? app.packageId
                                      : formTypeAndId.platform === 'ios'
                                      ? app.bundleId
                                      : ''
                              )
                            : [];
                    setApplications(apps || []);
                }
                if (result[0].status === 'fulfilled') {
                    let data = result[0].value?.data;
                    let config = formTypeAndId.platform === 'android' ? data.androidConfig : data.iosConfig;
                    obj =
                        formTypeAndId.platform === 'android'
                            ? {
                                  name: data?.name,
                                  saveAsBlocked: config?.saveAsBlocked ? RESTRICT : ALLOW,
                                  dataBackupBlocked: config?.dataBackupBlocked ? RESTRICT : ALLOW,
                                  printBlocked: config?.printBlocked ? RESTRICT : ALLOW,
                                  allowedInboundDataTransferSources: config?.allowedInboundDataTransferSources
                                      ? options.allowedOutboundDataTransferDestinations?.find(
                                            (option) => option.value === config?.allowedInboundDataTransferSources
                                        )
                                      : { label: 'All', value: 'allApps' },
                                  allowedOutboundDataTransferDestinations: config?.allowedOutboundDataTransferDestinations
                                      ? options.allowedOutboundDataTransferDestinations?.find(
                                            (option) => option.value === config?.allowedOutboundDataTransferDestinations
                                        )
                                      : { label: 'All', value: 'allApps' },
                                  allowedOutboundClipboardSharingLevel: config?.allowedOutboundClipboardSharingLevel
                                      ? options.allowedOutboundClipboardSharingLevel?.find(
                                            (option) => option.value === config?.allowedOutboundClipboardSharingLevel
                                        )
                                      : { label: 'All', value: 'allApps' },
                                  contactSyncBlocked: config?.contactSyncBlocked ? RESTRICT : ALLOW,
                                  deviceComplianceRequired: config?.deviceComplianceRequired ? REQUIRED : NOT_REQUIRED,
                                  encryptAppData: config?.encryptAppData ? REQUIRED : NOT_REQUIRED,
                                  disableAppEncryptionIfDeviceEncryptionIsEnabled: config?.disableAppEncryptionIfDeviceEncryptionIsEnabled
                                      ? RESTRICT
                                      : ALLOW,
                                  organizationalCredentialsRequired: config?.organizationalCredentialsRequired ? ALLOW : RESTRICT,
                                  managedBrowser: config?.customBrowserPackageId ? 'noConfig' : config?.managedBrowser,
                                  allowVoiceAssistant: config?.allowVoiceAssistant ? ALLOW : RESTRICT,
                                  screenCaptureBlocked: config?.screenCaptureBlocked ? RESTRICT : ALLOW,
                                  pinRequired: config?.pinRequired ? REQUIRED : NOT_REQUIRED,
                                  pinCharacterSet: config?.pinCharacterSet || 'numeric',
                                  fingerprintBlocked: config?.fingerprintBlocked ? RESTRICT : ALLOW,
                                  simplePinBlocked: config?.simplePinBlocked ? RESTRICT : ALLOW,
                                  disableAppPinIfDevicePinIsSet: config?.disableAppPinIfDevicePinIsSet ? NOT_REQUIRED : REQUIRED,
                                  minimumPinLength: config?.minimumPinLength
                                      ? options.minimumPinLength?.find((pin) => config?.minimumPinLength === pin.value)
                                      : { label: '4', value: 4 },
                                  maximumPinRetries: config?.maximumPinRetries,
                                  customBrowserPackageId: config?.customBrowserPackageId || '',
                                  customBrowserDisplayName: config?.customBrowserDisplayName || '',
                                  periodBeforePinReset: Number(config?.periodBeforePinReset?.replaceAll('P', '')?.replaceAll('D', '')),
                                  offlineDropdown: dropdownOpt?.offlineOptions?.find(
                                      (option) => option?.key === 'periodOfflineBeforeAccessCheck'
                                  ),
                                  offlineDropddownWipe: dropdownOpt?.offlineOptions?.find(
                                      (option) => option?.key === 'periodOfflineBeforeWipeIsEnforced'
                                  ),
                                  offlineInput: handleOfflineInput(config, 'periodOfflineBeforeAccessCheck'),
                                  offlineInputWipe: handleOfflineInput(config, 'periodOfflineBeforeWipeIsEnforced', true),
                                  minPatchVersionInput: config?.[handlePatchVersion(config, dropdownOpt.minPatchVersion)?.key],
                                  //   minPatchVersionInputWarn: config?.[handlePatchVersion(config, dropdownOpt.minPatchVersion)?.key],
                                  minPatchVersionDropdown: dropdownOpt.minPatchVersion?.find(
                                      (option) => option.key === handlePatchVersion(config, dropdownOpt.minPatchVersion)?.key
                                  ),
                                  /*
                                   *   minPatchVersionDropdownWarn: dropdownOpt.minPatchVersion?.find(
                                   *       (option) => option.key === handlePatchVersion(config, dropdownOpt.minPatchVersion)?.key
                                   *   ),
                                   */
                                  minOSVersionDropdown: dropdownOpt.minOSversion?.find(
                                      (option) => option.key === findDataObj(config, dropdownOpt.minOSversion)?.key
                                  ),
                                  minOSVersionInput: config?.[findDataObj(config, dropdownOpt.minOSversion)?.key],
                                  //   minOSVersionDropdownWarn: dropdownOpt.minOSversion?.find((option) => option.key === config?.[option.key]),
                                  minOSVersionInputWarn:
                                      config?.[dropdownOpt.minOSversion?.find((option) => option.key === config?.[option.key])?.key],
                                  minAppVersionDropdown: dropdownOpt.minappVersion?.find(
                                      (option) => option.key === findDataObj(config, dropdownOpt.minappVersion)?.key
                                  ),
                                  /*
                                   *   minAppVersionDropdownWarn: dropdownOpt.minappVersion?.find(
                                   *       (option) => option.key === config?.[option.key]
                                   *   ),
                                   */
                                  minAppVersionInput: config?.[findDataObj(config, dropdownOpt.minappVersion)?.key],
                                  // eslint-disable-next-line multiline-comment-style
                                  //   config?.[dropdownOpt.minappVersion?.find((option) => option.key === config?.[option.key])?.key],
                                  //   minAppVersionInputWarn: [
                                  //       dropdownOpt.minappVersion?.find((option) => option.key === config?.[option.key])?.key
                                  //   ],
                                  allowedDataStorageLocations: handleStorage(config?.allowedDataStorageLocations)
                              }
                            : {
                                  name: data.name,
                                  allowedInboundDataTransferSources: options.allowedInboundDataTransferSources?.find(
                                      (source) => source.value === config.allowedInboundDataTransferSources
                                  ),
                                  allowedOutboundDataTransferDestinations: options.allowedOutboundDataTransferDestinations?.find(
                                      (source) => source.value === config.allowedOutboundDataTransferDestinations
                                  ),
                                  organizationalCredentialsRequired: config.organizationalCredentialsRequired ? ALLOW : RESTRICT,
                                  allowedOutboundClipboardSharingLevel: options.allowedOutboundClipboardSharingLevel?.find(
                                      (sharingLevel) => sharingLevel.value === config.allowedOutboundClipboardSharingLevel
                                  ),
                                  dataBackupBlocked: config.dataBackupBlocked ? RESTRICT : ALLOW,
                                  deviceComplianceRequired: config.deviceComplianceRequired ? REQUIRED : NOT_REQUIRED,
                                  saveAsBlocked: config.saveAsBlocked ? RESTRICT : ALLOW,
                                  blockDataIngestionIntoOrganizationDocuments: config.blockDataIngestionIntoOrganizationDocuments
                                      ? RESTRICT
                                      : ALLOW,
                                  pinRequired: config.pinRequired ? REQUIRED : NOT_REQUIRED,
                                  maximumPinRetries: config.maximumPinRetries,
                                  appActionIfMaximumPinRetriesExceeded: options.appActionIfMaximumPinRetriesExceeded?.find(
                                      (action) => action.value === config.appActionIfMaximumPinRetriesExceeded
                                  ),
                                  simplePinBlocked: config.simplePinBlocked ? RESTRICT : ALLOW,
                                  pinCharacterSet: config.pinCharacterSet,
                                  periodBeforePinReset: config?.periodBeforePinReset
                                      ? Number(config?.periodBeforePinReset?.replaceAll('P', '')?.replaceAll('D', ''))
                                      : undefined,
                                  allowedDataStorageLocations: handleStorage(config?.allowedDataStorageLocations),
                                  contactSyncBlocked: config.contactSyncBlocked ? RESTRICT : ALLOW,
                                  printBlocked: config.printBlocked ? RESTRICT : ALLOW,
                                  fingerprintBlocked: config.fingerprintBlocked ? RESTRICT : ALLOW,
                                  disableAppPinIfDevicePinIsSet: config.disableAppPinIfDevicePinIsSet ? REQUIRED : NOT_REQUIRED,
                                  notificationRestriction: options.notificationRestriction?.find(
                                      (restrictions) => restrictions.value === config.notificationRestriction
                                  ),
                                  managedBrowser: config.managedBrowserToOpenLinksRequired ? 'custom' : config.managedBrowser,
                                  appDataEncryptionType: config.appDataEncryptionType,
                                  faceIdBlocked: config.faceIdBlocked ? RESTRICT : NOT_REQUIRED,
                                  thirdPartyKeyboardsBlocked: config.thirdPartyKeyboardsBlocked ? RESTRICT : ALLOW,
                                  syncPolicyManagedAppDataWithNativeApps: config.syncPolicyManagedAppDataWithNativeApps ? RESTRICT : ALLOW,
                                  exemptedAppProtocols: config.exemptedAppProtocols,
                                  exemptedUniversalLinks: config.exemptedUniversalLinks,
                                  managedUniversalLinks: config.managedUniversalLinks,
                                  offlineDropdown: dropdownOpt?.offlineOptions?.find(
                                      (option) => option?.key === 'periodOfflineBeforeAccessCheck'
                                  ),
                                  offlineDropddownWipe: dropdownOpt?.offlineOptions?.find(
                                      (option) => option?.key === 'periodOfflineBeforeWipeIsEnforced'
                                  ),
                                  offlineInput: handleOfflineInput(config, 'periodOfflineBeforeAccessCheck'),
                                  offlineInputWipe: handleOfflineInput(config, 'periodOfflineBeforeWipeIsEnforced', true),
                                  minAppVersionInput: config?.[findDataObj(config, dropdownOpt.miniosappversion)?.key],
                                  /*
                                   *   minAppVersionInputWarn: config.minimumWarningAppVersion,
                                   *   minAppVersionInputWipe: config.minimumWipeAppVersion,
                                   */
                                  minAppVersionDropdown: dropdownOpt.miniosappversion?.find(
                                      (option) => option.key === findDataObj(config, dropdownOpt.miniosappversion)?.key
                                  ),
                                  /*
                                   *   minAppVersionDropdownWarn: options.minAppVersionDropdowniosWarn[0],
                                   *   minAppVersionDropdownWipe: options.minAppVersionDropdowniosWipe[0],
                                   */
                                  minSDKVersionInputBlock: config?.[findDataObj(config, dropdownOpt.minsdkversion)?.key],
                                  /*
                                   *   minSDKVersionInputWarn: config.minimumWarningSdkVersion,
                                   *   minSDKVersionInputWipe: config.minimumWipeSdkVersion,
                                   */
                                  minSDKVersionDropdownBlock: dropdownOpt.minsdkversion?.find(
                                      (option) => option.key === findDataObj(config, dropdownOpt.minsdkversion)?.key
                                  ),
                                  /*
                                   *   minSDKVersionDropdownWarn: options.minSKDVersionDropdowniosWarn[0],
                                   *   minSDKVersionDropdownWipe: options.minSDKVersionDropdowniosWipe[0],
                                   */
                                  disabledAccountDropdown: options.disableAccount?.find(
                                      (action) => action.value === config.appActionIfUnableToAuthenticateUser
                                  ),
                                  /*
                                   *   mxOsVersionInputWipe: config.maximumWipeOsVersion,
                                   *   mxOsVersionInputWarn: config.maximumWarningOsVersion,
                                   */
                                  mxOsVersionInputreq: config?.[findDataObj(config, dropdownOpt.maxosversion)?.key],
                                  /*
                                   *   mxOsVersionDropdownWipe: options.maximumWipeOsVersion[0],
                                   *   mxOsVersionDropdownWarn: options.maximumWarningOsVersion[0],
                                   */
                                  mxOsVersionDropdownreq: dropdownOpt.maxosversion?.find(
                                      (option) => option.key === findDataObj(config, dropdownOpt.maxosversion)?.key
                                  ),
                                  /*
                                   *   minOsVersionInputWarn: config.minimumWarningOsVersion,
                                   *   minOsVersionInputWipe: config.minimumWipeOsVersion,
                                   */
                                  minOsVersionInputReq: config?.[findDataObj(config, dropdownOpt.miniosOSversion)?.key],
                                  /*
                                   *   minOsVersionDropdownWarn: options.minimumWarningOsVersion[0],
                                   *   minOsVersionDropdownWipe: options.minimumWipeOsVersion[0],
                                   */
                                  minOsVersionDropdownReq: dropdownOpt.miniosOSversion?.find(
                                      (option) => option.key === findDataObj(config, dropdownOpt.miniosOSversion)?.key
                                  ),
                                  pinRequiredInsteadOfBiometricTimeout: Number(
                                      config.pinRequiredInsteadOfBiometricTimeout?.replaceAll('PT', '').replaceAll('M', '')
                                  )
                              };
                    let appString = result[0].value?.data?.apps?.map((app) =>
                        formTypeAndId.platform === 'android' ? app.packageId : formTypeAndId.platform === 'ios' ? app.bundleId : ''
                    );
                    if (result[1].status === 'fulfilled') {
                        let appArr = await result[1].value?.filter((app) =>
                            appString?.includes(formTypeAndId.platform === 'android' ? app.packageId : app.bundleId?.toLowerCase())
                        );
                        setAppsArr(appArr);
                        result[1].value = result[1].value?.filter((app) =>
                            formTypeAndId.platform === 'android' ? app.packageId : formTypeAndId.platform === 'ios' ? app.bundleId : ''
                        );
                        let msApps = result[1].value?.filter((app) => app.publisher === 'Microsoft Corporation');
                        obj = defineAppType(obj, msApps, appArr, result[1].value);
                        let newArr = [];
                        result[1].value.forEach((app) => {
                            if (!appArr.map((val) => val.packageId).includes(app.packageId)) newArr.push(app);
                        });
                        setApplicationsBackup([...newArr]);
                    }
                }
                if (result[2].status === 'fulfilled') {
                    result[2].value.forEach((group) => {
                        group.members = group.members || '—';
                        group.checked = assignedGroups?.includes(group.groupId);
                    });
                    setGroups(result[2].value);
                }
                setFormData(obj);
                setValidation(obj);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const findDataObj = (data, arr) => {
        for (let obj of arr) {
            if (data?.[obj.key] !== null) {
                return obj;
            }
        }
    };
    const handleOfflineInput = (config, key, returnHours) => {
        let inputVal = handleAPPTime(config?.[key]);
        return returnHours ? inputVal / (60 * 24) : inputVal;
    };

    const handlePatchVersion = (config, arr) => {
        let result;
        arr.map((obj) => {
            let invalidReqVersion = config?.[obj.key]?.split('-')?.[0] === '0000';
            if (invalidReqVersion) return '';
            else {
                result = obj;
            }
        });
        return result;
    };

    const handleStorage = (storageLocations) => {
        let locationString = storageLocations?.join(',');
        return options.allowedDataStorageLocations?.filter((option) => locationString?.includes(option.value));
    };

    const defineAppType = (obj, msApps, appArr, allApps) => {
        if (msApps?.length === appArr.length) obj.appsType = 'allMicrosoftApps';
        else if (appArr.length < allApps?.length) obj.appsType = 'selectedPublicApps';
        else obj.appsType = 'allApps';
        return obj;
    };

    const checkRegex = (val) => {
        const regex = exp;
        return !regex.test(val);
    };

    const handleValidation = () => {
        let flag = [];
        let errors = {};
        (formTypeAndId.platform === 'ios'
            ? [
                  'minAppVersionInput',
                  'minAppVersionInputWarn',
                  'minSDKVersionInputBlock',
                  'minSDKVersionInputWipe',
                  'mxOsVersionInputWipe',
                  'mxOsVersionInputWarn',
                  'mxOsVersionInputreq',
                  'minOsVersionInputWarn',
                  'minOsVersionInputWipe',
                  'minOsVersionInputReq'
              ]
            : ['minOSVersionInput', 'minOSVersionInputWarn', 'minAppVersionInput', 'minAppVersionInputWarn']
        )?.map((field) => {
            if (validation.values[field]) {
                if (validation.values[field]) {
                    let check =
                        validation.values[field]?.endsWith('.') ||
                        validation.values[field]?.startsWith('.') ||
                        new RegExp('^d+$').test(validation.values[field]?.replaceAll('.', ''));
                    if (check) errors[field] = 'Invalid Version Number';
                    flag.push(check);
                }
            }
        });
        validation.setErrors(errors);
        return flag.includes(true);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { ...formData },
        onSubmit: (values) => {
            setLoading(true);
            let flag = handleValidation();
            if (flag) {
                setLoading(false);
                return;
            }
            const msApps = applications?.filter((app) => app.publisher === 'Microsoft Corporation');
            let apiService;
            let assignedGroups = groups
                ?.filter((group) => group.checked)
                ?.map((group) => {
                    return { target: { groupId: group.groupId } };
                });
            switch (editMode) {
                case 'apps':
                    apiService =
                        formTypeAndId.platform === 'android'
                            ? api.patch(
                                  url.MS_APP_PROTECTION + '/' + formTypeAndId.recordID + '/apps',
                                  {
                                      apps: (values.appsType === 'allApps'
                                          ? applications
                                          : values.appsType === 'allMicrosoftApps'
                                          ? msApps || []
                                          : appsArr
                                      ).map((app) => {
                                          return { packageId: app.packageId };
                                      })
                                  },
                                  false,
                                  domains.MDM_COMMONS
                              )
                            : api.patch(
                                  url.APPLE_APP_PROTECTION + '/' + formTypeAndId.recordID + '/apps',
                                  {
                                      apps: (values.appsType === 'allApps'
                                          ? applications
                                          : values.appsType === 'allMicrosoftApps'
                                          ? msApps || []
                                          : appsArr
                                      ).map((app) => {
                                          return { bundleId: app.bundleId };
                                      })
                                  },
                                  false,
                                  domains.MDM_COMMONS
                              );
                    break;
                case 'config':
                    apiService =
                        formTypeAndId.platform === 'android'
                            ? api.patch(
                                  url.MS_APP_PROTECTION + '/' + formTypeAndId.recordID,
                                  {
                                      appGroupType: validation.values.appsType,
                                      pinRequired: validation.values.pinRequired === REQUIRED,
                                      minimumPinLength: validation.values.minimumPinLength?.value,
                                      pinCharacterSet: validation.values.pinCharacterSet,
                                      simplePinBlocked: validation.values.simplePinBlocked === RESTRICT,
                                      periodBeforePinReset: validation.values?.periodBeforePinReset
                                          ? `P${validation.values.periodBeforePinReset}D`
                                          : undefined,
                                      //   [validation.values.maxAttemptsDropdown?.key]: `P${validation.values.pinMaxRetries}D`,
                                      disableAppPinIfDevicePinIsSet: validation.values.disableAppPinIfDevicePinIsSet === NOT_REQUIRED,
                                      organizationalCredentialsRequired: validation.values.organizationalCredentialsRequired === ALLOW,
                                      dataBackupBlocked: validation.values.dataBackupBlocked === RESTRICT,
                                      deviceComplianceRequired: validation.values.deviceComplianceRequired === REQUIRED,
                                      managedBrowserToOpenLinksRequired: validation.values.managedBrowser !== 'notConfigured',
                                      saveAsBlocked: validation.values.saveAsBlocked === RESTRICT,
                                      contactSyncBlocked: validation.values.contactSyncBlocked === RESTRICT,
                                      printBlocked: validation.values.printBlocked === RESTRICT,
                                      fingerprintBlocked: validation.values.fingerprintBlocked === RESTRICT,
                                      [dropdownOpt.offlineOptions.find((option) => option?.key === validation.values?.offlineDropdown?.key)
                                          ?.key]: validation.values.offlineInput ? `PT${validation.values.offlineInput}M` : undefined,
                                      [dropdownOpt.offlineOptions.find(
                                          (option) => option?.key === validation.values?.offlineDropddownWipe?.key
                                      )?.key]: validation.values.offlineInputWipe ? `P${validation.values.offlineInputWipe}D` : undefined,
                                      //   periodOnlineBeforeAccessCheck: 'PT60M',
                                      screenCaptureBlocked: validation.values.screenCaptureBlocked === RESTRICT,
                                      disableAppEncryptionIfDeviceEncryptionIsEnabled:
                                          validation.values.disableAppEncryptionIfDeviceEncryptionIsEnabled === RESTRICT,
                                      encryptAppData: validation.values.encryptAppData === REQUIRED,
                                      allowedOutboundDataTransferDestinations:
                                          validation.values.allowedOutboundDataTransferDestinations?.value,
                                      allowedInboundDataTransferSources: validation.values.allowedInboundDataTransferSources?.value,
                                      allowedOutboundClipboardSharingLevel: validation.values.allowedOutboundClipboardSharingLevel?.value,
                                      allowedDataStorageLocations:
                                          validation.values.saveAsBlocked === RESTRICT &&
                                          validation.values?.allowedOutboundDataTransferDestinations?.value === 'managedApps' &&
                                          validation.values?.allowedDataStorageLocations?.length > 0
                                              ? validation.values.allowedDataStorageLocations?.map((location) => location.value)
                                              : [],
                                      managedBrowser:
                                          validation.values.managedBrowser === 'noConfig'
                                              ? 'notConfigured'
                                              : validation.values.managedBrowser,
                                      [dropdownOpt?.minOSversion?.find(
                                          (option) => option.key === validation?.values?.minOSVersionDropdown?.key
                                      )?.key]: validation.values.minOSVersionInput,
                                      [dropdownOpt?.minOSversion?.find(
                                          (option) => option.key === validation?.values?.minOSVersionDropdownWarn?.key
                                      )?.key]: validation.values.minOSVersionInputWarn,
                                      // minimumWarningOsVersion: '9.0',
                                      [dropdownOpt?.minappVersion?.find(
                                          (option) => option.key === validation.values?.minAppVersionDropdown?.key
                                      )?.key]: validation.values.minAppVersionInput?.toString(),
                                      [dropdownOpt?.minappVersion?.find(
                                          (option) => option.key === validation.values?.minAppVersionDropdownWarn?.key
                                      )?.key]: validation.values.minAppVersionInputWarn?.toString(),
                                      // minimumWarningAppVersion: '7.5',
                                      [dropdownOpt.minPatchVersion?.find(
                                          (option) => option?.key === validation.values?.minPatchVersionDropdown?.key
                                      )?.key]: validation.values.minPatchVersionInput ? validation.values.minPatchVersionInput : undefined,
                                      [dropdownOpt.minPatchVersion?.find(
                                          (option) => option?.key === validation.values?.minPatchVersionDropdownWarn?.key
                                      )?.key]: validation.values.minPatchVersionInputWarn
                                          ? validation.values.minPatchVersionInputWarn
                                          : undefined,
                                      // minimumWarningPatchVersion: '2018-01-15',
                                      customBrowserPackageId:
                                          validation.values.managedBrowser === 'noConfig' && validation.values.customBrowserPackageId
                                              ? validation.values.customBrowserPackageId
                                              : undefined,
                                      customBrowserDisplayName:
                                          validation.values.managedBrowser === 'noConfig' && validation.values.customBrowserDisplayName
                                              ? validation.values.customBrowserDisplayName
                                              : undefined,
                                      maximumPinRetries: validation.values.maximumPinRetries
                                  },
                                  false,
                                  domains.MDM_COMMONS
                              )
                            : api.patch(
                                  url.APPLE_APP_PROTECTION + '/' + formTypeAndId.recordID,
                                  {
                                      version: '',
                                      appGroupType: validation.values.appsType,
                                      [dropdownOpt.offlineOptions.find((option) => option?.key === validation.values?.offlineDropdown?.key)
                                          ?.key]: validation.values.offlineInput ? `PT${validation.values.offlineInput}M` : undefined,
                                      [dropdownOpt.offlineOptions.find(
                                          (option) => option?.key === validation.values?.offlineDropddownWipe?.key
                                      )?.key]: validation.values.offlineInputWipe ? `P${validation.values.offlineInputWipe}D` : undefined,
                                      allowedInboundDataTransferSources: validation.values.allowedInboundDataTransferSources?.value,
                                      allowedOutboundDataTransferDestinations:
                                          validation.values.allowedOutboundDataTransferDestinations?.value,
                                      organizationalCredentialsRequired: validation.values.organizationalCredentialsRequired === ALLOW,
                                      allowedOutboundClipboardSharingLevel: validation.values.allowedOutboundClipboardSharingLevel?.value,
                                      dataBackupBlocked: validation.values.dataBackupBlocked === RESTRICT,
                                      deviceComplianceRequired: validation.values.deviceComplianceRequired === REQUIRED,
                                      saveAsBlocked: validation.values.saveAsBlocked === RESTRICT,
                                      pinRequired: validation.values.pinRequired === REQUIRED,
                                      /*
                                       * maximumPinRetries: validation.values.maximumPinRetries,
                                       * appActionIfMaximumPinRetriesExceeded: validation.values.appActionIfMaximumPinRetriesExceeded?.value,
                                       */
                                      simplePinBlocked: validation.values.simplePinBlocked === RESTRICT,
                                      pinCharacterSet: validation.values.pinCharacterSet,
                                      periodBeforePinReset: validation.values.periodBeforePinReset
                                          ? `P${validation.values.periodBeforePinReset}D`
                                          : undefined,
                                      allowedDataStorageLocations:
                                          validation.values.dataBackupBlocked === RESTRICT &&
                                          validation.values?.allowedOutboundDataTransferDestinations?.value === 'managedApps' &&
                                          validation.values?.allowedDataStorageLocations?.length > 0
                                              ? validation.values.allowedDataStorageLocations?.map((location) => location.value)
                                              : [],
                                      contactSyncBlocked: validation.values.contactSyncBlocked === RESTRICT,
                                      printBlocked: validation.values.printBlocked === RESTRICT,
                                      fingerprintBlocked: validation.values.fingerprintBlocked === RESTRICT,
                                      disableAppPinIfDevicePinIsSet: validation.values.disableAppPinIfDevicePinIsSet === NOT_REQUIRED,

                                      [dropdownOpt?.maxosversion?.find(
                                          (option) => option.key === validation.values?.mxOsVersionDropdownWipe?.key
                                      )?.key]: validation.values.mxOsVersionInputWipe,
                                      [dropdownOpt?.maxosversion?.find(
                                          (option) => option.key === validation.values?.mxOsVersionDropdownWarn?.key
                                      )?.key]: validation.values.mxOsVersionInputWarn,
                                      [dropdownOpt?.maxosversion?.find(
                                          (option) => option.key === validation.values?.mxOsVersionDropdownreq?.key
                                      )?.key]: validation.values.mxOsVersionInputreq?.toString(),
                                      [dropdownOpt?.miniosOSversion?.find(
                                          (option) => option.key === validation.values?.minOsVersionDropdownWarn?.key
                                      )?.key]: validation.values.minOsVersionInputWarn?.toString(),
                                      [dropdownOpt?.miniosOSversion?.find(
                                          (option) => option.key === validation.values?.minOsVersionDropdownWipe?.key
                                      )?.key]: validation.values.minOsVersionInputWipe?.toString(),
                                      [dropdownOpt?.miniosOSversion?.find(
                                          (option) => option.key === validation.values?.minOsVersionDropdownReq?.key
                                      )?.key]: validation.values.minOsVersionInputReq?.toString(),
                                      [dropdownOpt?.miniosappversion?.find(
                                          (option) => option.key === validation.values?.minAppVersionDropdown?.key
                                      )?.key]: validation.values.minAppVersionInput?.toString(),
                                      [dropdownOpt?.miniosappversion?.find(
                                          (option) => option.key === validation.values?.minAppVersionDropdownWarn?.key
                                      )?.key]: validation.values.minAppVersionInputWarn?.toString(),
                                      [dropdownOpt?.miniosappversion?.find(
                                          (option) => option.key === validation.values?.minAppVersionDropdownWipe?.key
                                      )?.key]: validation.values.minAppVersionInputWipe?.toString(),
                                      notificationRestriction: validation.values.notificationRestriction?.value,
                                      managedBrowser:
                                          validation.values.managedBrowser !== 'custom' ? validation.values.managedBrowser : undefined,
                                      isAssigned: assignedGroups?.length > 0,
                                      // pinRequiredInsteadOfBiometricTimeout: `PT${validation.values.pinRequiredInsteadOfBiometricTimeout}M`,
                                      appDataEncryptionType: validation.values.appDataEncryptionType,
                                      [dropdownOpt?.minsdkversion?.find(
                                          (option) => option.key === validation.values?.minSDKVersionDropdownBlock?.key
                                      )?.key]: validation.values.minSDKVersionInputBlock?.toString(),
                                      // minimumWarningSdkVersion: validation.values.minSDKVersionInputWarn,
                                      [dropdownOpt?.minsdkversion?.find(
                                          (option) => option.key === validation.values?.minSDKVersionDropdownWipe?.key
                                      )?.key]: validation.values.minSDKVersionInputWipe,
                                      faceIdBlocked: validation.values.faceIdBlocked === RESTRICT,
                                      thirdPartyKeyboardsBlocked: validation.values.thirdPartyKeyboardsBlocked === RESTRICT,
                                      appActionIfUnableToAuthenticateUser: validation.values.disabledAccountDropdown?.value,
                                      allowWidgetContentSync: false,
                                      gracePeriodToBlockAppsDuringOffClockHours: 'P1D',
                                      managedBrowserToOpenLinksRequired: false,
                                      maximumPinRetries: 1,
                                      periodOnlineBeforeAccessCheck: 'PT60M'

                                      /*
                                       *   exemptedAppProtocols: validation.values.exemptedAppProtocols,
                                       *   exemptedUniversalLinks: validation.values.exemptedUniversalLinks,
                                       *   managedUniversalLinks: validation.values.managedUniversalLinks
                                       */
                                  },
                                  false,
                                  domains.MDM_COMMONS
                              );
                    break;
                case 'assignment':
                    apiService =
                        formTypeAndId.platform === 'android'
                            ? api.patch(
                                  url.MS_APP_PROTECT + '/android/' + formTypeAndId.recordID + '/assign',
                                  { assignments: assignedGroups },
                                  false,
                                  domains.MDM_COMMONS
                              )
                            : api.patch(
                                  url.APPLE_APP_PROTECTION + '/' + formTypeAndId.recordID + '/assign',
                                  { assignments: assignedGroups },
                                  false,
                                  domains.MDM_COMMONS
                              );
                    break;
                default:
                    '';
            }
            apiService
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') {
                        toast.success(toastMessage[editMode]);
                        setEditMode('');
                        setMode('view');
                    }
                })
                .catch((err) => setLoading(false))
                .finally(() => {
                    handlePromise(formTypeAndId.platform === 'android' ? url.MS_APP_PROTECTION : url.APPLE_APP_PROTECTION);
                });
        }
    });

    const setValidation = (obj) => {
        validation.setValues({ ...obj });
    };

    const handleAddApp = (app, field) => {
        let vals = validation.values[field.value];
        vals?.push(app);
    };

    const handleVersionSelectChange = (version, app, valInd, valKey, key) => {
        app[key] = version;
        // eslint-disable-next-line no-unsafe-optional-chaining
        let vals = [...validation.values?.[valKey]];
        vals[valInd] = app;
        validation.setValues({ ...validation.values, [valKey]: vals });
    };

    const handleMultiplecheck = (field) => {
        let flag = true;
        field.multipleCheck?.forEach((check) => {
            flag =
                flag && field.showKey
                    ? validation.values[check]?.[field.showKey]
                        ? validation.values[check]?.[field.showKey] === field.showValue
                        : validation.values[check] === field.radVal
                    : false;
        });
        return flag;
    };

    const handleTabContent = (tabID, schema) => {
        return (
            <Row className="mt-3 mb-3">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    {schema[tabID]?.length > 0 ? (
                        schema[tabID]?.map(
                            (field, index) =>
                                (field.multipleCheck
                                    ? handleMultiplecheck(field)
                                    : field.showOn
                                    ? validation.values[field.showOn] === field.showValue
                                    : true) && <DynamicComponents key={index} field={field} validation={validation} formType={mode} />
                        )
                    ) : (
                        <React.Fragment>
                            {appVersionSchema?.map(
                                (versionObj, ind) =>
                                    (versionObj.showOn ? validation.values[versionObj.showOn] === versionObj.showValue : true) && (
                                        <DynamicComponents
                                            key={ind}
                                            field={versionObj}
                                            index={ind}
                                            validation={validation}
                                            formType={mode}
                                            handleAddApp={handleAddApp}
                                            handleVersionSelectChange={handleVersionSelectChange}
                                        />
                                    )
                            )}
                        </React.Fragment>
                    )}
                </Col>
            </Row>
        );
    };

    const tabsList = [
        {
            tabID: 'dataTransfer',
            tabName: 'Data Transfer',
            tabContent: handleTabContent('dataTransfer', dataProtectionSchema[formTypeAndId.platform])
        },
        // { tabID: 'appVersion', tabName: 'App Version', tabContent: handleTabContent('appVersion', dataProtectionSchema[formTypeAndId.platform]) },
        {
            tabID: 'compliance',
            tabName: 'Compliance',
            tabContent: handleTabContent('compliance', dataProtectionSchema[formTypeAndId.platform])
        },
        {
            tabID: 'encryption',
            tabName: 'Encryption',
            tabContent: handleTabContent('encryption', dataProtectionSchema[formTypeAndId.platform])
        },
        { tabID: 'network', tabName: 'Network', tabContent: handleTabContent('network', dataProtectionSchema[formTypeAndId.platform]) },
        {
            tabID: 'userExperience',
            tabName: 'User Experience',
            tabContent: handleTabContent('userExperience', dataProtectionSchema[formTypeAndId.platform])
        },
        {
            tabID: 'functionality',
            tabName: 'Functionality',
            tabContent: handleTabContent('functionality', dataProtectionSchema[formTypeAndId.platform])
        }
    ];

    const accessList = [
        {
            tabID: 'pinSettings',
            tabName: 'PIN Settings',
            tabContent: handleTabContent('pinSettings', ACCESS_REQUIREMENT_SCHEMA[formTypeAndId.platform])
        }
        // { tabID: 'MFA', tabName: 'MFA', tabContent: handleTabContent('mfa', ACCESS_REQUIREMENT_SCHEMA) }
    ];

    const conditionalLaunchList = [
        { tabID: 'app', tabName: 'App Conditions', tabContent: handleTabContent('app', CONDITIONAL_LAUNCH[formTypeAndId.platform]) },
        {
            tabID: 'device',
            tabName: 'Device Conditions',
            tabContent: handleTabContent('device', CONDITIONAL_LAUNCH[formTypeAndId.platform])
        }
    ];

    const handleCheckboxChange = (e, ind, row) => {
        let obj = row.original;
        obj.checked = e.target.checked;
        let groupArr = JSON.parse(JSON.stringify(groups));
        // groupArr.splice(ind, 1, obj);
        setGroups(groupArr);
    };

    const handleCheckAll = (e) => {
        let arr = JSON.parse(JSON.stringify(groups));
        arr.forEach((group) => (group.checked = !!e.target.checked));
        setGroups(arr);
    };

    const stepperData = [
        {
            title: 'Data Protection',
            icon: 1,
            disabled: false,
            renderTab: (
                <div>
                    <div className="fw-medium">Data Protection :</div>
                    <div className="fs-11 mb-3">
                        Data protection policies ensures that sensitive information remains secure by enforcing specific security measures
                        based on various conditions.
                    </div>
                    <Row>
                        <TabsComponent
                            tabsList={tabsList?.filter(
                                (listItem) => dataProtectionSchema[formTypeAndId.platform]?.[listItem.tabID]?.length > 0
                            )}
                            defaultTabID={'dataTransfer'}
                            noPadding={true}
                            toggleTab={(tab) => setSelectedTab({ ...selectedTab, threats: tab })}
                        />
                    </Row>
                </div>
            )
        },
        {
            title: 'Access Requirements',
            icon: 2,
            disabled: false,
            renderTab: (
                <div>
                    <div className="fw-medium">Access Requirements :</div>
                    <div className="fs-11 mb-3">
                        Access requirements define the criteria that must be met for users to gain access to applications, ensuring security
                        based on various conditions.
                    </div>
                    <Row>
                        <TabsComponent
                            tabsList={accessList}
                            defaultTabID={'pinSettings'}
                            noPadding={true}
                            toggleTab={(tab) => setSelectedTab(tab)}
                        />
                    </Row>
                </div>
            )
        },
        {
            title: 'Conditional Launch',
            icon: 3,
            disabled: false,
            renderTab: (
                <div>
                    <div className="fw-medium">Conditional Launch :</div>
                    <div className="fs-11 mb-3">
                        Conditional Launch enables you to establish criteria that must be met for an app to be accessed, ensuring that only
                        devices meeting your security standards can reach corporate data. You can also specify actions to be taken if a
                        device fails to meet these conditions.
                    </div>
                    <Row>
                        <TabsComponent
                            tabsList={conditionalLaunchList}
                            defaultTabID={'app'}
                            noPadding={true}
                            toggleTab={(tab) => setSelectedTab({ ...selectedTab, access: tab })}
                        />
                    </Row>
                </div>
            )
        }
    ];

    const handleSelectedTab = (tab) => {
        setSelectedTab(tab);
    };

    const handleChange = (option) => {
        const arr = [...appsArr];
        let arrBackup = JSON.parse(JSON.stringify(applicationsBackup));
        let ind = arrBackup.findIndex((app) => (option.packageId ? app.packageId === option.packageId : app.bundleId === option.bundleId));
        arrBackup.splice(ind, 1);
        setApplicationsBackup(arrBackup);
        arr.push(option);
        setAppsArr([...arr]);
    };

    const deleteApp = (index) => {
        let arr = [...appsArr];
        let arrBackup = JSON.parse(JSON.stringify(applicationsBackup));
        arrBackup.push(arr[index]);
        setApplicationsBackup(arrBackup);
        arr.splice(index, 1);
        setAppsArr([...arr]);
    };

    const disableSave = () => {
        let flag = [];
        switch (editMode) {
            case 'apps':
                if (validation.values.appsType === 'selectedPublicApps' && appsArr?.length === 0) flag.push(true);
                break;
            case 'congig':
                if (validation.values?.pinRequired === REQUIRED) {
                    flag.push(
                        validation.values?.pinCharacterSet === '' ||
                            validation.values?.fingerprintBlocked === '' ||
                            validation.values?.simplePinBlocked === '' ||
                            !validation.values?.maximumPinRetries ||
                            validation.values?.minimumPinLength === '' ||
                            !validation.values?.periodBeforePinReset ||
                            validation.values?.disableAppPinIfDevicePinIsSet === ''
                    );
                }
                if (validation.values.offlineInput && validation.values.offlineInput > 43799) flag.push(true);

                flag.push(handleValidation());
                break;
            default:
                '';
        }
        if (flag.includes(true)) return true;
        else return false;
    };

    return (
        <React.Fragment>
            {loading && <Loader />}{' '}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle="Edit App Protection Policy"
                        showBack={true}
                        backLink={`appprotectionpolicy/${formTypeAndId.domain}`}
                        history={history}
                        homeLink="Dashboard"
                    />
                    <Card>
                        <CardHeader>
                            <div className="fw-semibold fs-15 mb-1">
                                App Protection Policy (Microsoft)
                                <span className="badge-soft-success ms-1 p-1 rounded-3 fs-12">
                                    <img src={platformObj[formTypeAndId.platform].icon} alt="img" height={15} className="pe-1" />
                                    {platformObj[formTypeAndId.platform].label}
                                </span>
                            </div>
                            <span className="fs-13">
                                App Protection Policies are a set of security controls designed to manage how corporate data is accessed and
                                used within mobile applications. These policies safeguard sensitive information by enforcing PIN
                                requirements, data encryption, compliance checks and restricting data sharing between apps.
                            </span>
                            <div className="mt-2">
                                <div>
                                    <Label className={'mb-0 fw-medium d-flex'}> Configuration Name</Label>
                                </div>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={4} xl={3}>
                                        <div className="input-group mt-2">
                                            <Input
                                                name={'name'}
                                                id={'name'}
                                                className="form-control"
                                                type={'text'}
                                                disabled={true}
                                                onChange={validation.handleChange}
                                                onBlur={validation?.handleBlur}
                                                value={validation?.values?.name || ''}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </CardHeader>
                        <CardBody className={`${!validation?.values?.name ? 'mask pe-none' : ''}`}>
                            {mode === 'view' ? (
                                <>
                                    <div className="mb-2">
                                        <div className="fw-medium d-flex align-items-center text-primary gap-2 fs-14 pe-2">
                                            Applications :
                                            <i
                                                className="ri-edit-box-line fs-18 cursor-pointer"
                                                onClick={() => {
                                                    setEditMode('apps');
                                                    setMode('edit');
                                                }}
                                            />
                                        </div>
                                        <div className="fs-10 mb-4">
                                            Choose the applications for which conditional access policies are enforced
                                        </div>
                                    </div>
                                    <div className="mb-2 fw-semibold">
                                        {radioField?.radios?.find((option) => option.val === validation.values.appsType)?.label}
                                    </div>
                                    {validation.values.appsType === 'allApps' || validation.values.appsType === 'allMicrosoftApps' ? (
                                        <div className="bg-soft-info py-2 px-4 rounded-3 mb-3 fs-11">
                                            <span className="pe-1 fs-13 fw-medium">Note :</span>App protection policies are enforced on all
                                            the Microsoft apps including partnered apps
                                        </div>
                                    ) : (
                                        <Row>
                                            {appsArr?.map((app, ind) => (
                                                <Col key={ind} sm={3}>
                                                    <Card className="card-height-100 shadow-card mb-4 border-top">
                                                        <CardBody className="position-relative text-center">
                                                            {/* <div
                                                                className="position-absolute top-0 end-0 cursor-pointer"
                                                                onClick={() => deleteApp(ind)}
                                                            >
                                                                <i className="ri-close-line fs-14" />
                                                            </div> */}
                                                            {/* <img src={''} alt="img" height={40} /> */}
                                                            <div className="pt-1 fw-semibold" id={`app-${ind}`}>
                                                                {app.name}
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    )}
                                    <div className="mb-2">
                                        <div className="fw-medium d-flex align-items-center text-primary gap-2 fs-14 pe-2">
                                            Advanced Settings
                                            {!editMode && (
                                                <i
                                                    className="ri-edit-box-line fs-18 cursor-pointer"
                                                    onClick={() => {
                                                        setEditMode('config');
                                                        setMode('edit');
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div className="mt-2">
                                            <div className="fs-14 fw-medium">Data protection : </div>
                                            <div className="fs-11 mt-1">
                                                Data protection policies ensures that sensitive information remains secure by enforcing
                                                specific security measures based on various conditions.
                                            </div>
                                        </div>
                                        <Row className="mt-2">
                                            <TabsComponent
                                                tabsList={tabsList?.filter(
                                                    (listItem) => dataProtectionSchema[formTypeAndId.platform]?.[listItem.tabID]?.length > 0
                                                )}
                                                defaultTabID={'dataTransfer'}
                                                noPadding={true}
                                                toggleTab={(tab) => handleSelectedTab(tab)}
                                            />
                                        </Row>
                                        <div>
                                            <div className="fs-14 fw-medium">Access Requirements : </div>
                                            <div className="fs-11 mt-1">
                                                Access requirements define the criteria that must be met for users to gain access to
                                                applications, ensuring security based on various conditions.
                                            </div>
                                        </div>
                                        <Row className="mt-2">
                                            <TabsComponent
                                                tabsList={accessList}
                                                defaultTabID={'pinSettings'}
                                                noPadding={true}
                                                toggleTab={(tab) => setSelectedTab(tab)}
                                            />
                                        </Row>
                                        <div>
                                            <div className="fs-14 fw-medium">Conditional Launch :</div>
                                            <div className="fs-11 mt-1">
                                                Conditional Launch enables you to establish criteria that must be met for an app to be
                                                accessed, ensuring that only devices meeting your security standards can reach corporate
                                                data. You can also specify actions to be taken if a device fails to meet these conditions.
                                            </div>
                                        </div>
                                        <Row className="mt-2">
                                            <TabsComponent
                                                tabsList={conditionalLaunchList}
                                                defaultTabID={'app'}
                                                noPadding={true}
                                                toggleTab={(tab) => setSelectedTab({ ...selectedTab, access: tab })}
                                            />
                                        </Row>
                                    </div>
                                    <div className="mx-2">
                                        <div className="fw-medium d-flex align-items-center text-primary gap-2 fs-14 pe-2">
                                            Assignment
                                            {!editMode && (
                                                <i
                                                    className="ri-edit-box-line fs-18 cursor-pointer"
                                                    onClick={() => {
                                                        setEditMode('assignment');
                                                        setMode('edit');
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div className="fs-11 mb-3">
                                            After configuring the policy settings, assign the policy to user groups. You can either select
                                            specific groups or apply the policy to all users.
                                        </div>
                                        <Row>
                                            <TableContainer
                                                loading={loading}
                                                columns={groupsSchema?.filter((schema) => schema.Header !== 'checkbox')}
                                                data={groups?.filter((group) => group.checked)}
                                                handleCheckboxChange={handleCheckboxChange}
                                                handleCheckAll={handleCheckAll}
                                                className="custom-header-css"
                                                divClass="table-responsive table-card"
                                                tableClass="table table-nowrap table-border table-centered align-middle"
                                                theadClass="bg-light text-muted"
                                            />
                                        </Row>
                                    </div>
                                </>
                            ) : editMode === 'apps' ? (
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <div className="fw-medium mb-1">Applications :</div>
                                        <div className="fs-10 mb-4">
                                            Choose the application for which app protection policies are enforced.
                                        </div>
                                        <DynamicComponents field={radioField} validation={validation} />
                                        {validation.values.appsType === 'selectedPublicApps' && (
                                            <div>
                                                <div className="fs-13 fw-medium my-1">Specific App</div>
                                                <div className="fs-11 text-muted mb-1	">
                                                    App protection policies are enforced on selected applications
                                                </div>
                                                <Select
                                                    getOptionValue={(option) => {
                                                        option.packageId ? option.packageId : option.bundleId;
                                                    }}
                                                    getOptionLabel={(option) => option.name}
                                                    options={sortBy(applicationsBackup, 'name')}
                                                    placeholder="Select"
                                                    value={''}
                                                    onChange={(selectedOption) => {
                                                        handleChange(selectedOption);
                                                    }}
                                                    isSearchable={true}
                                                    noOptionsMessage={() => 'No data found'}
                                                />
                                                <Row className="mt-3">
                                                    {appsArr?.length > 0 &&
                                                        appsArr.map((app, ind) => (
                                                            <Col key={ind} sm={3}>
                                                                <Card className="card-height-100 shadow-card mb-4 border-top">
                                                                    <CardBody className="position-relative text-center">
                                                                        <div
                                                                            className="position-absolute top-0 end-0 cursor-pointer"
                                                                            onClick={() => deleteApp(ind)}
                                                                        >
                                                                            <i className="ri-close-line fs-14" />
                                                                        </div>
                                                                        {/* <img src={''} alt="img" height={40} /> */}
                                                                        <div className="pt-1 fw-semibold" id={`app-${ind}`}>
                                                                            <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                                                {app.name}
                                                                            </EllipsisToolTip>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        ))}
                                                </Row>
                                            </div>
                                        )}
                                        {(validation.values.appsType === 'allApps' ||
                                            validation.values.appsType === 'allMicrosoftApps') && (
                                            <div className="bg-soft-info py-2 px-4 rounded-3 fs-11">
                                                <span className="pe-1 fs-13 fw-medium">Note :</span>
                                                {validation.values.appsType === 'allApps'
                                                    ? 'App protection policies are enforced on micorsoft and microsoft partnered apps'
                                                    : 'App protection policies are enforced on microsoft apps'}
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            ) : editMode === 'config' ? (
                                <CustomStepperComponent
                                    cancelBtn={true}
                                    stepperData={stepperData}
                                    customCancel={() => {
                                        setMode('view');
                                        setEditMode('');
                                        handlePromise(
                                            formTypeAndId.platform === 'android' ? url.MS_APP_PROTECTION : url.APPLE_APP_PROTECTION
                                        );
                                    }}
                                    enableSaveBtn={true}
                                    handleSave={validation.handleSubmit}
                                    formType={mode}
                                />
                            ) : (
                                <div>
                                    <div className="fw-medium">Assignment :</div>
                                    <div className="fs-11 mb-3">
                                        After configuring the policy settings, assign the policy to user groups. You can either select
                                        specific groups or apply the policy to all users.
                                    </div>
                                    <div className="h-100 ">
                                        <div className="mb-3 mb-lg-0 d-flex flex-column gap-4">
                                            <div className="mb-3 mb-lg-0 w-100 shadow-card">
                                                <div className="padding-8 fs-14 border-radius-top-left-4 border-radius-top-right-4">
                                                    <div className="d-flex align-items-center fw-medium fs-12 justify-content-between">
                                                        <div className="d-flex align-items-center ">
                                                            <i className="ri-database-2-fill me-2" />
                                                            <span>All Groups </span>({groups.length})
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="height-400 border-radius-bottom-left-4 border-radius-bottom-right-4 p-3 overflow-auto">
                                                    {groups?.length > 0 ? (
                                                        <TableContainer
                                                            loading={loading}
                                                            columns={groupsSchema}
                                                            data={groups}
                                                            isGlobalFilter={true}
                                                            hideCheckBoxTooltip={true}
                                                            handleCheckAll={handleCheckAll}
                                                            handleCheckboxChange={handleCheckboxChange}
                                                            searchFilter2={true}
                                                            className="custom-header-css"
                                                            divClass="table-responsive table-card"
                                                            tableClass="table table-nowrap table-border table-centered align-middle"
                                                            theadClass="bg-light text-muted"
                                                        />
                                                    ) : (
                                                        <NoRecordsFound />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </CardBody>
                        {(editMode === 'apps' || editMode === 'assignment') && (
                            <CardFooter>
                                <div className="d-flex align-items-center justify-content-end">
                                    <Row>
                                        <Col>
                                            <Button
                                                type="button"
                                                className="btn btn-light w-100"
                                                onClick={() => {
                                                    setMode('view');
                                                    setEditMode('');
                                                    handlePromise(
                                                        formTypeAndId.platform === 'android'
                                                            ? url.MS_APP_PROTECTION
                                                            : url.APPLE_APP_PROTECTION
                                                    );
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                size="md"
                                                type="button"
                                                className="d-flex align-items-center justify-content-center w-100"
                                                color={'primary'}
                                                disabled={!validation.values?.name}
                                                // disabled={disableSave()}
                                                onClick={validation.handleSubmit}
                                            >
                                                {mode === 'edit' ? 'Update' : 'Save'}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </CardFooter>
                        )}
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EditApp;
