/* eslint-disable max-nested-callbacks */
/* eslint-disable max-depth */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import { TenantConfig, sortByAsc } from '../../../Components/Common/Util';
import AndroidVersion from '../../Dashboard/AndroidVersion';
import DashboardWidgets from '../../Dashboard/DashboardWidgets';
import Manufacturer from '../../Dashboard/Manufacturer';
import OnlineOfflineChart from '../../Dashboard/OnlineOfflineChart';

import huawei from '../../../assets/images/huawei.png';
import itel from '../../../assets/images/itel.png';
import acer from '../../../assets/images/logos/acer.png';
import asus from '../../../assets/images/logos/asus.png';
import blackview from '../../../assets/images/logos/blackview.png';
import droid from '../../../assets/images/logos/droid.png';
import fusion5 from '../../../assets/images/logos/fusion5.png';
import hmd from '../../../assets/images/logos/hmd.png';
import iball from '../../../assets/images/logos/iball.png';
import ibrit from '../../../assets/images/logos/ibrit.png';
import ikall from '../../../assets/images/logos/ikall.png';
import infinixmobilitylimited from '../../../assets/images/logos/infinixmobilitylimited.png';
import lava from '../../../assets/images/logos/lava.png';
import lenovo from '../../../assets/images/logos/lenovo.png';
import lg from '../../../assets/images/logos/lg.png';
import mi from '../../../assets/images/logos/mi.png';
import motorola from '../../../assets/images/logos/motorola.png';
import nalogo from '../../../assets/images/logos/nalogo.png';
import oneplus from '../../../assets/images/logos/oneplus.png';
import oppo from '../../../assets/images/logos/oppo.png';
import panasonic from '../../../assets/images/logos/panasonic.png';
import polaroid from '../../../assets/images/logos/polaroid.png';
import realme from '../../../assets/images/logos/realme.png';
import samsung from '../../../assets/images/logos/samsung.png';
import swipe from '../../../assets/images/logos/swipe.png';
import viewSonic from '../../../assets/images/logos/viewsonic.png';
import vivo from '../../../assets/images/logos/vivo.png';
import wishtel from '../../../assets/images/logos/wishtel.png';
import tcl from '../../../assets/images/tcl.png';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';

const AndroidTvDashboard = () => {
    document.title = 'TV Dashboard';
    const [loading, setLoading] = useState(false);
    const urlconf = useEnv();
    let history = useHistory();
    const tenant = TenantConfig();
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showOSDetailsModal, setShowOSDetailsModal] = useState(false);
    const [selectedTab, setSelectedTab] = useState(tenant.ANDROID_ENTERPRISE_ENABLED ? 'mobile' : 'tv');

    const [tvOnlineStatus, setTvOnlineStatus] = useState();
    const [tvWidgetCount, setTvWidgetCount] = useState({});
    const [tvTotalDevices, setTvTotalDevices] = useState(0);
    const [tvSeries, setTvSeries] = useState();
    const [tvLabels, setTvLabels] = useState();
    const api = new APIClient();
    const [tvManufacturerDetails, setTvManufacturerDetails] = useState([]);
    const [tvAndroidVersionDetails, setTvAndroidVersionDetails] = useState([]);

    const manufacturerLogos = {
        samsung: samsung,
        realme: realme,
        oppo: oppo,
        vivo: vivo,
        wishtel: wishtel,
        xiaomi: mi,
        'hmd global': hmd,
        lenovo: lenovo,
        acer: acer,
        motorola: motorola,
        panasonic: panasonic,
        itel: itel,
        tcl: tcl,
        huawei: huawei,
        na: nalogo,
        lava: lava,
        fusion5: fusion5,
        oneplus: oneplus,
        ibrit: ibrit,
        blackview: blackview,
        iball: iball,
        ikall: ikall,
        asus: asus,
        swipe: swipe,
        polaroid: polaroid,
        'infinix mobility limited': infinixmobilitylimited,
        droidlogic: droid,
        amlogic: droid,
        lg: lg,
        viewsonic: viewSonic,
        mediatek: acer
    };
    const toggle = useCallback(async () => {
        setShowDetailsModal(!showDetailsModal);
    }, [showDetailsModal]);

    const toggle1 = useCallback(async () => {
        setShowOSDetailsModal(!showOSDetailsModal);
    }, [showOSDetailsModal]);
    const handleLoader = (val) => {
        setLoading(val);
    };
    const getTvMetricCount = () => {
        setLoading(true);
        api.get(url.TV_DASHBOARD)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    setTvWidgetCount({
                        totalCount: resp?.data?.totalCount ? resp.data.totalCount : 0,
                        enrolledCount: resp?.data?.enrolledDevices ? resp.data.enrolledDevices : 0,
                        notEnrolledCount: resp?.data?.notEnrolledDevices ? resp.data.notEnrolledDevices : 0,
                        onlineCount: resp?.data?.onlineStatusCount?.ONLINE ? resp.data?.onlineStatusCount?.ONLINE : 0,
                        offlineCount: resp?.data?.onlineStatusCount?.OFFLINE ? resp.data?.onlineStatusCount?.OFFLINE : 0
                    });
                    let onlineArr = [
                        { label: '0 to 7 days', count: resp?.data?.tvDeviceOnlineSummary?.ONLINE_7, key: 3 },
                        { label: '0 to 15 days', count: resp?.data?.tvDeviceOnlineSummary?.ONLINE_15, key: 2 },
                        { label: '0 to 30 days', count: resp?.data?.tvDeviceOnlineSummary?.ONLINE_30, key: 1 }
                    ];
                    setTvTotalDevices(resp?.data?.tvDeviceOnlineSummary?.ONLINE_30);

                    let manufacturerArr = [];
                    let totalDeviceCountAsManufacturer = 0;
                    for (let [key, value] of Object.entries(resp.data?.deviceDistribution)) {
                        if (key !== '') {
                            manufacturerArr.push({
                                label: key.charAt(0).toUpperCase() + key.slice(1),
                                count: value
                            });
                            totalDeviceCountAsManufacturer = totalDeviceCountAsManufacturer + value;
                        }
                    }
                    let mfArr = [];
                    manufacturerArr.forEach((obj) => {
                        if (!mfArr.find((mf) => mf.label?.toLowerCase() === obj.label?.toLowerCase())) {
                            let mfAr = manufacturerArr.filter((mfObj) => mfObj.label?.toLowerCase() === obj.label?.toLowerCase());
                            let count = 0;
                            mfAr.forEach((mf) => (count = count + (mf.count ? Number(mf.count) : 0)));
                            mfArr.push({ label: obj.label, count: count });
                        }
                    });
                    mfArr = sortByAsc(mfArr, 'count');
                    if (resp.data?.deviceDistribution?.['']) mfArr.push({ label: 'NA', count: resp.data?.deviceDistribution?.[''] });

                    let androidVersionArr = [];
                    for (let [key, value] of Object.entries(resp.data?.osDistribution)) {
                        if (key !== '') androidVersionArr.push({ label: 'Android ' + key, count: value });
                    }
                    let osArr = [];
                    androidVersionArr.forEach((av) => {
                        if (!osArr.find((os) => os.label?.toLowerCase() === av.label?.toLowerCase())) {
                            let ofAr = androidVersionArr.filter((avObj) => avObj.label?.toLowerCase() === av.label?.toLowerCase());
                            let count = 0;
                            ofAr.forEach((ofObj) => (count = count + (ofObj.count ? Number(ofObj.count) : 0)));
                            osArr.push({ label: av.label, count: count });
                        }
                    });
                    osArr = sortByAsc(osArr, 'count');
                    if (resp.data?.osDistribution?.['']) osArr.push({ label: 'NA', count: resp.data?.osDistribution?.[''] });

                    let arr = onlineArr.sort((a, b) => b.key - a.key);
                    setTvManufacturerDetails(mfArr);
                    setTvAndroidVersionDetails(osArr);
                    setTvOnlineStatus(arr);
                    setTvSeries(arr.map((ele) => ele.count));
                    setTvLabels(arr.map((ele) => ele.label));
                }
                setLoading(false);
            })
            .catch((_err) => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getTvMetricCount();
    }, []);

    const handleTvExport = (type) => {
        if (type === 'pdf') {
            window.print('divToDownload');
        } else {
            setLoading(true);
            const applicationList = [];
            if (tenant.TV_WIDGETS?.includes('dashboard_device_online_summary')) {
                applicationList.push(
                    ['Total', 'Enrolled', 'Not Enrolled', 'Online', 'Offline'],
                    [
                        tvWidgetCount?.totalCount ? tvWidgetCount?.totalCount : '0',
                        tvWidgetCount?.enrolledCount ? tvWidgetCount?.enrolledCount : '0',
                        tvWidgetCount?.notEnrolledCount ? tvWidgetCount?.notEnrolledCount : '0',
                        tvWidgetCount?.onlineCount ? tvWidgetCount?.onlineCount : '0',
                        tvWidgetCount?.offlineCount ? tvWidgetCount?.offlineCount : '0'
                    ]
                );
            }
            if (tenant.TV_WIDGETS?.includes('dashboard_device_online_summary'))
                applicationList.push(
                    [],
                    ['Device Online Summary'],
                    ['0 to 7 days', '0 to 15 days', '0 to 30 days'],
                    [
                        tvOnlineStatus?.find((status) => status.label === '0 to 7 days')?.count
                            ? tvOnlineStatus.find((status) => status.label === '0 to 7 days')?.count
                            : '0',
                        tvOnlineStatus?.find((status) => status.label === '0 to 15 days')?.count
                            ? tvOnlineStatus.find((status) => status.label === '0 to 15 days')?.count
                            : '0',
                        tvOnlineStatus?.find((status) => status.label === '0 to 30 days')?.count
                            ? tvOnlineStatus.find((status) => status.label === '0 to 30 days')?.count
                            : '0'
                    ]
                );
            if (tenant.TV_WIDGETS?.includes('dashboard_os_distribution')) {
                applicationList.push([], ['OS Distribution'], ['OS Version', 'Number of Devices']);
                tvAndroidVersionDetails?.forEach((androidVersion) => {
                    applicationList.push([androidVersion.label, androidVersion.count]);
                });
            }
            if (tenant.TV_WIDGETS?.includes('dashboard_device_distribution')) {
                applicationList.push([], ['Device Distribution'], ['Device Manufacturer', 'Number of Devices']);
                tvManufacturerDetails?.forEach((manufacturer) => {
                    applicationList.push([manufacturer.label, manufacturer.count]);
                });
            }

            var csvFileData = applicationList;
            var csv = '';
            csvFileData.forEach(function (row) {
                csv += row.join(',');
                csv += '\n';
            });

            // document.write(csv);
            var a = document.createElement('a');
            a.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
            a.target = '_blank';
            a.download = 'Dashboard TV Report.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setLoading(false);
        }
    };
    const handleTabContent = (key) => {
        return (
            <>
                <BreadCrumb pageTitle="Dashboard" history={history} homeLink="Dashboard" hideLinks={true} handleExport={handleTvExport} />
                <div id="divToDownloadTv" className="divToDownload">
                    <Row>
                        {tenant.TV_WIDGETS?.includes('dashboard_devices') && (
                            <DashboardWidgets widgetCount={tvWidgetCount} handleLoader={handleLoader} tv={true} />
                        )}
                    </Row>
                    <Row>
                        {tenant.TV_WIDGETS?.includes('dashboard_device_online_summary') && (
                            <Col xl={4} md={4}>
                                <OnlineOfflineChart
                                    series={tvSeries || []}
                                    onlineStatus={tvOnlineStatus || []}
                                    labels={tvLabels || []}
                                    totalDevices={tvTotalDevices}
                                    handleLoader={handleLoader}
                                    tv={true}
                                />
                            </Col>
                        )}
                        <Col xl={4} md={4}>
                            {tenant.TV_WIDGETS?.includes('dashboard_os_distribution') && (
                                <AndroidVersion
                                    details={tvAndroidVersionDetails || []}
                                    showDetailsModal={() => setShowOSDetailsModal(true)}
                                    tv={true}
                                />
                            )}
                        </Col>
                        <Col xl={4} md={4}>
                            {tenant.TV_WIDGETS?.includes('dashboard_device_distribution') && (
                                <Manufacturer
                                    details={tvManufacturerDetails || []}
                                    showDetailsModal={() => setShowDetailsModal(true)}
                                    tv={true}
                                />
                            )}
                        </Col>
                    </Row>
                </div>
            </>
        );
    };
    const handleOffcanvasBody = () => {
        let arr = selectedTab === 'mobile' ? '' : tvManufacturerDetails;
        return (
            <div className="pt-2 ps-4 pe-4 bg-white">
                <ul className="list-group list-group-flush border border-2 border-dashed mb-0 mt-2">
                    {arr?.length > 0 &&
                        arr.map((ele, index) => {
                            return (
                                <li key={index} className="list-group-item px-0">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 ms-2">
                                            <img src={manufacturerLogos[ele.label?.toLowerCase()]} alt={ele.label} width={100} />
                                        </div>
                                        <div className="flex-shrink-0">
                                            <p className="fs-14 mb-0">{ele.count}</p>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            </div>
        );
    };
    const handleOSOffcanvasBody = () => {
        let arr = selectedTab === 'mobile' ? '' : tvAndroidVersionDetails;
        return (
            <div className="pt-2 ps-4 pe-4 bg-white">
                <ul className="list-group list-group-flush border border-2 border-dashed mb-0 mt-2">
                    {arr?.length > 0 &&
                        arr.map((ele, index) => {
                            return (
                                <li key={index} className="list-group-item px-0">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 ms-2">
                                            <p className="fs-16 text-primary fw-medium">{ele.label}</p>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <p className="fs-14 mb-0">{ele.count}</p>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            </div>
        );
    };
    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>{handleTabContent('tv')}</Container>
                <OffcanvasModal
                    direction="end"
                    toggle={toggle}
                    open={showDetailsModal}
                    handleCloseClick={toggle}
                    OffcanvasModalID="manufacturer_details"
                    hideSaveButton={true}
                    closeButtonBorder={true}
                    handleOffcanvasBody={handleOffcanvasBody}
                    modalClassName={'w-25'}
                    offcanvasHeader={'Manufacturer Details'}
                />
                <OffcanvasModal
                    direction="end"
                    toggle={toggle1}
                    open={showOSDetailsModal}
                    handleCloseClick={toggle1}
                    OffcanvasModalID="os_details"
                    hideSaveButton={true}
                    closeButtonBorder={true}
                    handleOffcanvasBody={handleOSOffcanvasBody}
                    modalClassName={'w-25'}
                    offcanvasHeader={'OS Details'}
                />
            </div>
        </React.Fragment>
    );
};

export default AndroidTvDashboard;
